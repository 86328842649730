import React from 'react'
import styled from 'styled-components'
import { Frame } from './elements'

const ToolbarStyles = styled.div`
  height: 40px;
  flex-shrink: 0;
  background-color: white;
  box-shadow: 1px 2px 5px rgba(0,0,0,0.20), 1px 2px 5px rgba(0,0,0,0.16);
  z-index: 1;
`

const ToolbarContainer = styled(Frame)`
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
`

function Toolbar(props) {
  return (
    <ToolbarStyles>
      <ToolbarContainer>
        {props.children}
      </ToolbarContainer>
    </ToolbarStyles>
  )
}

export default Toolbar
