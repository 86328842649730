export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE'

export const STORE_TOKEN_REQUEST = 'STORE_TOKEN_REQUEST'
export const STORE_TOKEN_SUCCESS = 'STORE_TOKEN_SUCCESS'
export const STORE_TOKEN_FAILURE = 'STORE_TOKEN_FAILURE'

export const login = payload => ({
  type: USER_LOGIN_REQUEST,
  payload,
})

export const loginSuccess = payload => ({
  type: USER_LOGIN_SUCCESS,
  payload,
})

export const loginFailure = payload => ({
  type: USER_LOGIN_FAILURE,
  payload,
})

export const logout = () => ({
  type: USER_LOGOUT_REQUEST,
})

export const logoutSuccess = () => ({
  type: USER_LOGOUT_SUCCESS,
})

export const logoutFailure = payload => ({
  type: USER_LOGOUT_FAILURE,
  payload,
})

export const storeToken = payload => ({
  type: STORE_TOKEN_REQUEST,
  payload,
})

export const storeTokenSuccess = payload => ({
  type: STORE_TOKEN_SUCCESS,
  payload,
})

export const storeTokenFailure = payload => ({
  type: STORE_TOKEN_FAILURE,
  payload,
})
