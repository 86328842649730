import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { camelize, titleize } from 'inflected'
import { Asset } from '../../models'
import Condition from '../../api/condition'
import Form from './form'

class AssetEditor extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validate = this.validate.bind(this)
    this.state = { message: '' }
  }

  componentDidMount() {
    const { asset, onReload } = this.props
    if (asset.id) onReload()
  }

  handleAPIError({ original: { error } }, setErrors) {
    const { validationErrors } = error
    if (!validationErrors) return
    const errors = {}
    Object.entries(validationErrors).forEach(([field, messages]) => {
      const localFieldName = camelize(field, false)
      const label = titleize(field)
      const singleMessage = messages.map(m => `${label} ${m}`).join(' ')
      errors[localFieldName] = singleMessage
    })
    setErrors(errors)
  }

  handleSubmit(values, { setSubmitting, setErrors } ) {
    const { asset, onUpdate, onCreate } = this.props
    this.setState({message: ''})
    let submitFunc = null
    if (asset.id) {
      submitFunc = onUpdate
    } else {
      if (values.confirmSerialNumber !== values.serialNumber) {
        return setErrors({ 'confirmSerialNumber': 'The confirmation number must match the serial number.' })
      }
      submitFunc = onCreate
    }
    return submitFunc(asset.merge(values))
      .then(() => this.setState({message: 'Asset saved. No worries.'}))
      .catch(error => this.handleAPIError(error, setErrors))
      .finally(() => setSubmitting(false))
  }

  validate(values) {
    const { asset } = this.props
    const errors = {}
    if (asset.id === null) {
      if (!values.serialNumber) {
        errors.serialNumber = 'A Serial Number is required.'
      }
      if (values.serialNumber !== values.confirmSerialNumber) {
        errors.confirmSerialNumber = 'The Serial Numbers does not match its confirmation.'
      }
      if (!values.confirmSerialNumber) {
        errors.confirmSerialNumber = 'You cannot change the serial number after saving the record. Please confirm the value.'
      }
    }

    return errors
  }

  render() {
    const { asset, onCancel, condition } = this.props
    const { message } = this.state
    const initialValues = {
      name: asset.name,
      serialNumber: asset.id ? asset.serialNumber : '',
      confirmSerialNumber: '',
    }
    return(
      <div>
        <h3>Editing {asset.name || asset.serialNumber}</h3>
        <Formik
          initialValues={initialValues}
          validate={this.validate}
          onSubmit={this.handleSubmit}
          component={fkProps => <Form {...fkProps} message={message} onCancel={onCancel} readOnlyValues={asset.toJS()}/>}
          initialState={condition.name}
        />
      </div>
    )
  }
}

AssetEditor.propTypes = {
  selector: PropTypes.func.isRequired,
  asset: PropTypes.instanceOf(Asset).isRequired,
  onCancel: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  condition: PropTypes.instanceOf(Condition).isRequired,
}

export default connect((state, ownProps) => ({asset: ownProps.selector(state)}))(AssetEditor)
