import { takeLatest, all, call } from 'redux-saga/effects'
import { FORGOT_PASSWORD_REQUEST } from './actions'
import API from '../../api'

function* forgotPassword({ payload }) {
  yield call([API, API.forgotPassword], payload.email)
}

export default function* root() {
  yield all([
    yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword),
  ])
}
