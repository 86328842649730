import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import Select from 'react-select'
import Modal from 'react-modal'
import Scanner from '../scanner'
import API from '../../api'
import {
  BoldButton,
  ModalStyles,
  ModalHeader,
  ModalSubHeader,
  ModalBody,
  ModalActions,
  ReadyIndicator,
} from '../elements'
import {
  checkin as checkinAction,
  checkout as checkoutAction,
  fetchScannedAsset as fetchScannedAssetAction,
  cacheScannedAsset as cacheScannedAssetAction,
} from './actions'

class CheckinManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeModal() {
    this.setState({ serial: null })
    this.props.clearScannedAsset()
  }

  onScan(serial) {
    this.setState({serial})
    this.props.fetchScannedAsset(serial)
  }

  checkoutPressed() {
    const opID = this.state.selectedOperator
    const assetID = this.props.asset.id
    this.props.checkout({
      operatorID: opID,
      assetID,
      callback: this.props.refresh,
    })
    this.closeModal()
  }

  checkinPressed() {
    const assetID = this.props.asset.id
    this.props.checkin({
      assetID,
      callback: this.props.refresh,
    })
    this.closeModal()
  }

  renderCheckout() {
    const options = this.props.operators
      .filter(o => o.isActive())
      .map(o => ({ label: `${o.firstName} ${o.lastName}`.trim(), value: o.id }))
    return (
      <>
        <ModalHeader>Asset Check Out</ModalHeader>
        <ModalSubHeader>Serial: {this.state.serial}</ModalSubHeader>
        <ModalBody>
          Please select an operator to assign the asset to.
        </ModalBody>
        <ModalActions>
          <Select
            styles={{
              container: () => ({
                flex: 1,
              }),
            }}
            placeholder="Select an operator"
            options={options}
            isSearchable
            onChange={o => this.setState({selectedOperator: o.value})} />
          <BoldButton onClick={()=>this.checkoutPressed()}>Check Out</BoldButton>
        </ModalActions>
      </>
    )
  }

  renderCheckin() {
    const opID = this.props.asset.userId
    const op = this.props.operators.find(o => o.id === opID) || { firstName: 'an operator' }
    return (
      <>
        <ModalHeader>Asset Check In</ModalHeader>
        <ModalSubHeader>Serial: {this.state.serial}</ModalSubHeader>
        <ModalBody>
          Asset is currently checked out to {`${op.firstName} ${op.lastName}`.trim()}.
        </ModalBody>
        <ModalActions>
          <BoldButton onClick={()=>this.checkinPressed()}>Check In</BoldButton>
        </ModalActions>
      </>
    )
  }

  renderMissing() {
    return (
      <>
        <ModalHeader>Asset Not Found</ModalHeader>
        <ModalSubHeader>Serial: {this.state.serial}</ModalSubHeader>
        <ModalBody>
          An asset must be registered before it can be used.
        </ModalBody>
      </>
    )
  }

  renderLoadingSpinner() {
    return (
      <>
        <ModalHeader>{'Asset Loading'}<ReadyIndicator /></ModalHeader>
        <ModalSubHeader>Serial: {this.state.serial}</ModalSubHeader>
      </>
    )
  }

  renderAssetLost() {
    return (
      <>
        <ModalHeader>Asset Lost</ModalHeader>
        <ModalSubHeader>Serial: {this.state.serial}</ModalSubHeader>
        <ModalBody>
          Asset is reported as lost. You must report the asset as found before you can check it in or out.
        </ModalBody>
      </>
    )
  }

  renderModalContent() {
    const { asset, assetNotFound } = this.props
    if (asset != null && asset.discardedAt != null) { return this.renderAssetLost() }
    if (asset != null && asset.userId != null) { return this.renderCheckin() }
    if (asset != null) { return this.renderCheckout() }
    if (assetNotFound) { return this.renderMissing() }
    return this.renderLoadingSpinner()
  }

  render() {
    return (
      <>
        <Scanner onRead={serial => this.onScan(serial)} />
        <Modal
          isOpen={this.state.serial != null}
          onRequestClose={() => this.closeModal()}
          style={ModalStyles}
          contentLabel='Asset Manager Check Out Modal'
          ariaHideApp={false}
        >
          {this.renderModalContent()}
        </Modal>
      </>
    )
  }
}

CheckinManager.propTypes = {
  operators: PropTypes.instanceOf(List).isRequired,
  checkin: PropTypes.func,
  checkout: PropTypes.func,
  refresh: PropTypes.func,
  clearScannedAsset: PropTypes.func,
  fetchScannedAsset: PropTypes.func,
  assetNotFound: PropTypes.bool,
}

const mapStateToProps = state => ({
  asset: state.get('checkinManager').get('asset'),
  assetNotFound: state.get('checkinManager').get('assetNotFound'),
  operators: API.selectorFor('Operator')(state),
})

const mapDispatchToProps = {
  checkin: checkinAction,
  checkout: checkoutAction,
  clearScannedAsset: cacheScannedAssetAction,
  fetchScannedAsset: fetchScannedAssetAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinManager)
