export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKIN_REQUEST = 'CHECKIN_REQUEST'
export const CACHE_SCANNED_ASSET = 'CACHE_SCANNED_ASSET'
export const FETCH_SCANNED_ASSET = 'FETCH_SCANNED_ASSET'

export const checkout = payload => ({
  type: CHECKOUT_REQUEST,
  payload,
})

export const checkin = payload => ({
  type: CHECKIN_REQUEST,
  payload,
})

export const cacheScannedAsset = (asset = null, assetNotFound = false) => ({
  type: CACHE_SCANNED_ASSET,
  payload: {
    asset,
    assetNotFound,
  },
})

export const fetchScannedAsset = serial => ({
  type: FETCH_SCANNED_ASSET,
  payload: { serial },
})
