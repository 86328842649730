import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { camelize, titleize } from 'inflected'
import Form from './form'

function OperatorEditor({
  operator,
  condition,
  onReload,
  onUpdate,
  onCreate,
  onCancel,
  onDeactivate,
  onRecover,
  onInitiatePasswordReset,
}) {
  const [requery, setRequery] = useState(true)
  useEffect(() => {
    if (
      requery && condition.isReady() &&
      operator && !operator.isNewRecord()
    ) { onReload(operator) }
    return () => setRequery(false)
  }, [requery, operator, condition])

  const processAPIErrors = (error, setErrors) => {
    if (!error) return
    if (!error.original) return
    if (!error.original.error) return
    if (!error.original.error.validationErrors) return

    const { original: { error: { validationErrors } } } = error
    const errors = {}
    Object.entries(validationErrors).forEach(([field, messages]) => {
      const localFieldName = camelize(field, false)
      const label = titleize(field)
      const singleMessage = messages.map(m => `${label} ${m}`).join(' ')
      errors[localFieldName] = singleMessage
    })
    if (validationErrors.persona) {
      errors.role = validationErrors.persona.map(m => `Role ${m}`).join(' ')
    }
    setErrors(errors)
  }

  // React complains if asynchronous functions try to make updates
  // after the component has unmounted.
  const mounted = useRef(true)
  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  })

  const [message, setMessage] = useState('')

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    let submitFunc
    if (operator.id) {
      submitFunc = onUpdate
    } else {
      submitFunc = onCreate
    }
    setMessage('')
    submitFunc(operator.merge(values))
      .then(() => mounted.current && setMessage("That worked. You're doing great!"))
      .catch(error => mounted.current && processAPIErrors(error, setErrors))
      .finally(() => mounted.current && setSubmitting(false))
  }

  const validate = values => {
    const errors = {}
    if (!values.lastName) {
      errors.lastName = 'Last Name is required.'
    }
    if (!values.firstName) {
      errors.lastName = 'First Name is required.'
    }
    if (!values.role) {
      errors.role = 'Role is required.'
    }
    if (!['Store Manager', 'Store Associate'].includes(values.role)) {
      errors.role = 'Role must be either Manager or Associate'
    }
    if (operator.isNewRecord()) {
      if (values.role === 'Store Manager') {
        if (!values.email) {
          errors.email = 'An email is requried for a Store Manager'
        }
      } else if (values.email) {
        errors.email = 'A Store Associate has no Email address'
      }
    } else {
      if (values.email !== operator.email) {
        errors.email = 'Email cannot be changed'
      }
      if (values.role !== operator.role) {
        errors.role = 'Role cannot be changed'
      }
    }
    return errors
  }

  const initialValues = {
    firstName: operator.firstName,
    lastName: operator.lastName,
    role: operator.role || 'Store Associate',
    email: operator.email,
  }

  return <Formik
    initialValues={initialValues}
    component={props => <Form
      {...props}
      onRecover={onRecover}
      onDeactivate={onDeactivate}
      onCancel={onCancel}
      onInitiatePasswordReset={onInitiatePasswordReset}
      message={message}
      operator={operator}
    />}
    onSubmit={handleSubmit}
    validate={validate}
  />
}

const mapStateToProps = (state, ownProps) => ({
  operator: ownProps.selector(state),
})

export default connect(mapStateToProps)(OperatorEditor)
