import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { useFormik } from 'formik'
import { login as loginAction } from './actions'
import LoginError from './loginError'
import './styles.css'
import ProgressIndicator from '../progressIndicator'
import styled from 'styled-components'
import { Frame, BoldButton } from '../elements'

const LoginBtn = styled(BoldButton)`
  margin: 0pt;
`

const LoginContainer = styled(Frame)`
  padding: 8pt;
`

const ForgotLink = styled.div`
  margin-top: 16pt;
  margin-bottom: 8pt;
  a {
    padding: 8pt 0pt;
    color: ${p => p.theme.primary};
    font-size: 11pt;
    font-weight: ${p => p.theme.buttonWeight};
  }
`

function Login({ login, isLoading, error }) {
  const usernameRef = useRef(null)

  useEffect(() => {
    usernameRef.current.focus()
  }, [])

  const validate = values => {
    const errors = {}

    if (!values.username) {
      errors.username = 'Required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
    ) {
      errors.username = 'Invalid email address'
    }

    if (!values.password) {
      errors.password = 'Required'
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validate,
    onSubmit: values => {
      login(values)
    },
  })
  return (
    <LoginContainer>
      <ProgressIndicator loading={isLoading} />
      {error ? <LoginError error={error} /> : null}
      <form onSubmit={formik.handleSubmit}>
        <input
          ref={usernameRef}
          id="username"
          className="login-input"
          name="username"
          type="text"
          placeholder="Username"
          onChange={formik.handleChange}
          value={formik.values.username}
        />
        {formik.touched.username && formik.errors.username ? (
          <div className="login-validation">{formik.errors.username}</div>
        ) : null}

        <input
          id="password"
          className="login-input"
          name="password"
          type="password"
          placeholder="Password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className="login-validation">{formik.errors.password}</div>
        ) : null}
        <ForgotLink><NavLink to='/forgot_password'>Forgot Password</NavLink></ForgotLink>
        <LoginBtn type="submit">
          Log In
        </LoginBtn>
      </form>
    </LoginContainer>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  error: PropTypes.string,
}

Login.defaultProps = {
  error: null,
}

const mapStateToProps = state => ({
  isLoading: (state.get('auth') || {}).isLoading,
  error: (state.get('auth') || {}).error,
})

export default connect(
  mapStateToProps, {
    login: loginAction,
  })(withRouter(Login))
