import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchAssetHistory } from './actions'
import { selectAssetHistory } from './selectors'
import Table from '../table'
import DateTime from '../dateTime'

const columns = () => [
  {
    Header: 'Assigned To',
    accessor: 'assignedTo',
    Cell: ({value}) => <div>{value || 'Unassigned'}</div>,
  }, {
    Header: 'Assigned By',
    accessor: 'assignedBy',
    Cell: ({value}) => <div>{value || 'Unknown'}</div>,
  }, {
    Header: 'Assigned At',
    accessor: 'assignedAt',
    Cell: ({value}) => <DateTime value={value}/>,
  },
]

class AssetHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { asset, fetch } = this.props
    fetch(asset.id)
  }

  render() {
    const { asset, operationEvents } = this.props
    return (
      <>
        <h3>History of {asset.name || asset.serialNumber}</h3>
        <Table
          data={operationEvents}
          columnSelector={columns}
          initialSortBy={[{ id: 'assignedAt', desc: true }]}
        />
      </>
    )
  }
}

AssetHistory.propTypes = {
  asset: PropTypes.any.isRequired,
  operationEvents: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  operationEvents: selectAssetHistory(state),
})

const mapDispatchToProps = {
  fetch: fetchAssetHistory,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetHistory)
