const StoreIDKey = 'storeID'
const OrgSubdomainKey = 'subdomain'
const save = (key, val) => {
  try {
    localStorage.setItem(key, JSON.stringify(val))
  } catch {
    return false
  }
  return true
}

const get = key => JSON.parse(localStorage.getItem(key))

const clear = key => {
  try {
    localStorage.removeItem(key)
  } catch {
    return false
  }
  return true
}

export function useStoreID(storeID) {
  if (storeID === null) {
    return clear(StoreIDKey)
  }
  switch (typeof storeID) {
    case 'undefined':
      return get(StoreIDKey)
    case 'number':
      return save(StoreIDKey, storeID)
    default:
      throw new Error(`Unsupported type for store ID: ${typeof StoreID}`)
  }
}

export function useOrgSubdomain(subdomain) {
  if (subdomain === null) {
    return clear(OrgSubdomainKey)
  }
  switch (typeof subdomain) {
    case 'undefined':
      return get(OrgSubdomainKey)
    case 'string':
      return save(OrgSubdomainKey, subdomain)
    default:
      throw new Error(`Unsupported type for subdomain: ${typeof StoreID}`)
  }
}
