import React from 'react'
import { List } from 'immutable'
import PropTypes from 'prop-types'
import Table from './table'
import { Button } from '../elements'

function OperatorList({ operators, onEditClick }) {
  const data = operators.toArray()

  const columns = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
        id: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        id: 'lastName',
      },
      {
        Header: 'Role',
        accessor: 'role',
        id: 'role',
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => {
          const { original } = row
          return (
            <Button type="button" onClick={() => onEditClick(original)}>
              Edit
            </Button>
          )
        },
        id: 'actions',
      },
    ],
    [],
  )

  return (
    <div>
      <Table columns={columns} data={data} />
    </div>
  )
}

OperatorList.propTypes = {
  operators: PropTypes.instanceOf(List).isRequired,
  onEditClick: PropTypes.func.isRequired,
}

export default OperatorList
