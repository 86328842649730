import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { OperatorEditor, OperatorList } from '../../components'
import API from '../../api'
import Condition from '../../api/condition'
import {
  PageContainer,
  PageContentContainer,
  H2,
  H3,
  Button,
  ReadyIndicator,
  ErrorText,
  Flash,
  Row,
} from '../../components/elements'
import Toolbar from '../../components/toolbar'
import { Operator } from '../../models'

function Operators({
  operators,
  index,
  reload,
  update,
  create,
  destroy,
  customAction,
  condition,
  match: { params: { operatorID } },
  history,
  api,
  error,
}) {

  const [focus, setFocus] = useState(null)
  const [showDeactivated, setShowDeactivated] = useState(false)
  const [requery, setRequery] = useState(true)
  const [message, setMessage] = useState('')
  const clearMessage = () => setMessage('')

  console.log('focus:')
  console.log(focus)

  console.log(`operatorID:${operatorID}`)

  const initialFocus = (
    operatorID &&
    operators.find(operator => operator.id === (1*operatorID))
  ) || (operatorID === 'new' ? new Operator() : false)
  if (initialFocus && !initialFocus.equals(focus)) {
    console.log('setting focus')
    setFocus(initialFocus)
  }

  const query = { with_inactive: showDeactivated ? 'only' : false }

  useEffect(() => {
    if (condition.isReady() && requery) {
      index('Operator', query)
    }
    return () => setRequery(false)
  }, [condition, showDeactivated, requery])

  const handleShowDeactivatedClick = () => {
    clearMessage()
    setShowDeactivated(!showDeactivated)
    setRequery(true)
  }

  const handleEditClick = operator => {
    clearMessage()
    history.push(`/operators/${operator.id}`)
  }

  const handleAddClick = () => {
    clearMessage()
    history.push('/operators/new')
  }

  const handleCancel = () => {
    clearMessage()
    history.push('/operators')
    setFocus(null)
    setRequery(true)
  }

  const handleDeactivate = () => {
    clearMessage()
    history.push('/operators')
    setFocus(null)
    destroy(focus)
      .then(() => setMessage('Poof! Gone!'))
      .catch(() => setMessage('Well, this is awkward. You will want to refresh the page.'))
  }

  const handleRecover = () => {
    clearMessage()
    customAction('recover', focus)
      .then(() => setMessage(`${focus.firstName} is back!`))
      .catch(() => setMessage('You should refresh the page to be sure.'))
  }

  const handleInitiatePasswordReset = () => {
    clearMessage()
    customAction('initiatePasswordReset', focus)
      .then(() => setMessage(`An email has been sent to ${focus.firstName} with a link to the reset form.`))
      .catch(() => setMessage(`The email might not have gone out to ${focus.firstName}.`))
  }

  const getToolbar = () => focus ? <Toolbar /> : (
    <Toolbar>
      <Button small onClick={handleShowDeactivatedClick}>
        Show {showDeactivated ? 'Active' : 'Deactivated'} Operators
      </Button>
      <Button small onClick={handleAddClick}>
        Create New Operator
      </Button>
    </Toolbar>
  )

  const handleCreate = newOperator => {
    const promise = create(newOperator)
    promise.then(returnedOperator => {
      history.push(`/operators/${returnedOperator.id}`)
    })
    return promise
  }

  const getPage = () => focus ? (
    <OperatorEditor
      selector={api.selectorFor('Operator', focus.id)}
      condition={condition}
      onReload={reload}
      onUpdate={update}
      onCreate={handleCreate}
      onCancel={handleCancel}
      onDeactivate={handleDeactivate}
      onRecover={handleRecover}
      onInitiatePasswordReset={handleInitiatePasswordReset}
    />
  ) : (
    <OperatorList
      operators={operators}
      index={index}
      onEditClick={handleEditClick}
      condition={condition}
    />
  )

  return (
    <PageContainer>
      {getToolbar()}
      <PageContentContainer>
        <Row>
          <H2><ReadyIndicator status={condition.name}/></H2>
          <Flash message={message} onClear={clearMessage}/>
        </Row>
        {error && <H3><ErrorText>{error}</ErrorText></H3>}
        {getPage()}
      </PageContentContainer>
    </PageContainer>
  )
}

Operators.propTypes = {
  operators: PropTypes.instanceOf(List).isRequired,
  condition: PropTypes.instanceOf(Condition).isRequired,
  index: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  operators: API.selectorFor('Operator')(state),
  condition: API.conditionSelectorFor('Operator')(state),
  error: API.errorSelectorFor('Operator')(state),
  api: API,
})

export default connect(mapStateToProps, API.curriedDispatches)(Operators)
