import React from 'react'
import { forgotPassword } from './actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  BoldButton,
  InputField,
  PageContentContainer,
} from '../../components/elements'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
  }

  onSubmit() {
    this.props.submit(this.emailField.value)
    this.setState({ submitted: true })
  }

  cancel() {
    this.props.history.goBack()
  }

  render() {
    return (
      <PageContentContainer>
        <h4>Enter your email to request password reset instructions.</h4>
        <InputField ref={f => this.emailField = f} />
        {(this.state.submitted) ? (
          <h3>Request submitted. If there is an account associated with that email, we will send instructions to reset your password.</h3>
        ) : (<BoldButton onClick={()=>this.onSubmit()}>Submit</BoldButton>)}
        <BoldButton onClick={()=>this.cancel()}>Back</BoldButton>
      </PageContentContainer>
    )
  }
}

export default connect(null, {
  submit: forgotPassword,
})(withRouter(ForgotPassword))
