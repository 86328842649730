import { REPORT_LOST_REQUEST, REPORT_FOUND_REQUEST } from './actionTypes'

export const reportLost = (assetID, callback) => ({
  type: REPORT_LOST_REQUEST,
  payload: { assetID, callback },
})

export const reportFound = (assetID, callback) => ({
  type: REPORT_FOUND_REQUEST,
  payload: { assetID, callback },
})
