import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import './progressIndicator.css'

export default function ProgressIndicator({loading}) {
  return (
    <div className="progress-indicator">
      <BeatLoader color="#557598" loading={loading} size={20} />
    </div>
  )
}
