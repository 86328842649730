import { List, Map } from 'immutable'
import ModelSpec from './model_spec'
import { Asset, Operator } from '../models'
import AuthError from './auth_error'
import Hook from './hook'
import { USER_LOGOUT_REQUEST, logout } from '../components/login/actions'

class API {
  constructor(host, clientID, clientSecret) {
    this.host = host
    this.clientID = clientID
    this.clientSecret = clientSecret
    this.models = List()
    this.reducer = this.reducer.bind(this)
    this.curriedDispatches = this.curriedDispatches.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  // Fallback error handler. Consumers can still handle promise
  // rejections in their own way.
  handleError(error, dispatch) {
    this.lastError = error
    console.log('API ERROR', error)
    if (error.original) {
      console.log('ORIGINAL ERROR', error.original)
    }
    if (error.constructor.name === 'AuthError') {
      dispatch(logout())
    }
    // TODO: Do something more better.
  }

  registerModel(klass) {
    const spec = new ModelSpec(klass)
    this.models = this.models.push(spec)
  }

  index(dispatch, modelName, query) {
    const model = this._modelByName(modelName)
    const hook = Hook.withPromise()
    dispatch({ type: model.actionType('INDEX'), payload: { query, hook} })
    hook.promise.catch(error => this.handleError(error, dispatch)) // The caller can still handle errors more appropriately.
    return hook.promise
  }

  fetch(dispatch, modelName, id) {
    const model = this._modelByName(modelName)
    const hook = Hook.withPromise()
    dispatch({ type: model.actionType('FETCH'), payload: { id, hook } } )
    hook.promise.catch(error => this.handleError(error, dispatch)) // The caller can still handle errors more appropriately.
    return hook.promise
  }

  reload(dispatch, record) {
    const model = this._modelByName(record.constructor.name)
    const hook = Hook.withPromise()
    dispatch({ type: model.actionType('RELOAD'), payload: { record, hook} })
    hook.promise.catch(error => this.handleError(error, dispatch)) // The caller can still handle errors more appropriately.
    return hook.promise
  }

  create(dispatch, record) {
    const model = this._modelByName(record.constructor.name)
    const hook = Hook.withPromise()
    dispatch({ type: model.actionType('CREATE'), payload: { record, hook } })
    hook.promise.catch(error => this.handleError(error, dispatch)) // The caller can still handle errors more appropriately.
    return hook.promise
  }

  update(dispatch, record) {
    const model = this._modelByName(record.constructor.name)
    const hook = Hook.withPromise()
    dispatch({ type: model.actionType('UPDATE'), payload: { record, hook } })
    hook.promise.catch(error => this.handleError(error, dispatch)) // The caller can still handle errors more appropriately.
    return hook.promise
  }

  destroy(dispatch, record) {
    const model = this._modelByName(record.constructor.name)
    const hook = Hook.withPromise()
    dispatch({ type: model.actionType('DESTROY'), payload: { record, hook } })
    hook.promise.catch(error => this.handleError(error, dispatch)) // The caller can still handle errors more appropriately.
    return hook.promise
  }

  customAction(dispatch, actionName, record) {
    const model = this._modelByName(record.constructor.name)
    const hook = Hook.withPromise()
    if (!record.constructor.customActions) {
      hook.reject(new Error(`${record.constructor.name} record doesn't support custom actions.`))
      return hook.promise
    }
    if (!record.constructor.customActions.has(actionName)) {
      hook.reject(new Error(`${record.constructor.name} record doesn't support the ${actionName} custom action`))
      return hook.promise
    }
    const customAction = record.constructor.customActions.get(actionName).withContext(record)
    dispatch({ type: model.actionType('CUSTOM'), payload: { customAction, hook } })
    hook.promise.catch(error => this.handleError(error, dispatch))
    return hook.promise
  }

  curriedDispatches(dispatch) {
    return {
      index: (modelName, query) => this.index(dispatch, modelName, query),
      fetch: (modelName, id) => this.fetch(dispatch, modelName, id),
      reload: record => this.reload(dispatch, record),
      create: record => this.create(dispatch, record),
      update: record => this.update(dispatch, record),
      destroy: record => this.destroy(dispatch, record),
      customAction: (actionName, record) => this.customAction(dispatch, actionName, record),
    }
  }

  reducer(state = Map(), {type, payload}) {
    if (type === USER_LOGOUT_REQUEST) {
      return this.models.reduce((modelState, spec) => spec.clear(modelState), state)
    }
    return this.models.reduce((modelState, spec) => spec.reduce(modelState, {type, payload}), state)
  }

  sagas() {
    return this.models.flatMap(spec => (
      [
        spec.makeIndexSaga(this),
        spec.makeFetchSaga(this),
        spec.makeReloadSaga(this),
        spec.makeCreateSaga(this),
        spec.makeUpdateSaga(this),
        spec.makeDestroySaga(this),
        spec.makeCustomActionSaga(this),
      ]
    )).toArray()
  }

  selectorFor(modelName, id = null) {
    const spec = this._modelByName(modelName)
    if (!spec) return () => null
    return spec.selector(id)
  }

  conditionSelectorFor(modelName) {
    const spec = this._modelByName(modelName)
    if (!spec) return () => null
    return spec.conditionSelector()
  }

  errorSelectorFor(modelName) {
    const spec = this._modelByName(modelName)
    if (!spec) return () => null
    return spec.errorSelector()
  }

  *modelsByCollection(object) {
    for (const [key, list] of Object.entries(object)) {
      const spec = this._modelByCollection(key)
      if (spec) yield [spec, list]
    }
  }

  _modelByCollection(apiCollection) {
    return this.models.find(spec => spec.apiCollection() === apiCollection)
  }

  _modelByName(modelName) {
    return this.models.find(spec => spec.name === modelName)
  }

  async simpleFetch(token, method, endpoint, body) {
    const headers = {}
    const options = {
      method,
      headers,
    }
    if (body) {
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(body)
    }
    if (token) options.headers.Authorization = `Bearer ${token}`
    const response = await fetch(
      `${this.host}/${endpoint}`,
      options,
    )

    const result = { status: response.status }
    try {
      result.body = (response.ok && response.body) ? await response.json() : null
    } catch (e) {
      console.warn(e)
    }
    return result
  }

  async request(token, method, endpoint, bodyObject) {
    this.lastError = null
    const headers = {}
    const options = {
      method,
      headers,
    }
    if (bodyObject) {
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(bodyObject)
    }
    if (token) options.headers.Authorization = `Bearer ${token}`
    const response = await fetch(
      `${this.host}/${endpoint}`,
      options,
    )
    const responsePromise = await response.json()

    if (response.status === 401) {
      const responseObj = await responsePromise
      const err = new AuthError(responseObj.error.message)
      err.original = responseObj
      throw err
    } else if (response.status < 200 || response.status >= 400) {
      const responseObj = await responsePromise
      const err = new Error(responseObj.error.message)
      err.original = responseObj
      throw err
    }
    return responsePromise
  }

  authenticate(payload) {
    const data = {
      authentication: {
        authType: 'password',
        clientId: this.clientID,
        clientSecret: this.clientSecret,
        username: payload.username,
        password: payload.password,
      },
    }

    return this.request(
      null,
      'POST',
      'api/authentications',
      data,
    )
  }

  deauthenticate(token) {
    return this.request(
      token,
      'DELETE',
      'api/authentications',
    )
  }

  updateAsset(token, assetID, data) {
    return this.request(
      token,
      'PATCH',
      `api/physical_keys/${assetID}`,
      { physical_key: data },
    )
  }

  checkin(token, assetID) {
    return this.updateAsset(token, assetID, { userId: null })
  }

  checkout(token, assetID, operatorID) {
    return this.updateAsset(token, assetID, { userId: operatorID })
  }

  reportLost(token, assetID) {
    return this.request(
      token,
      'DELETE',
      `api/physical_keys/${assetID}`,
    )
  }

  reportFound(token, assetID) {
    return this.updateAsset(token, assetID, { discardedAt: null })
  }

  fetchAssetHistory(token, assetID) {
    return this.request(
      token,
      'GET',
      `api/assets/${assetID}/history`,
    )
  }

  fetchAssetBySerial(token, serial) {
    return this.simpleFetch(
      token,
      'GET',
      `api/physical_keys/${serial}`,
    )
  }

  forgotPassword(email) {
    return this.simpleFetch(
      null,
      'POST',
      'api/passwords/forgot?domain=api',
      { user: { username: email } },
    )
  }
}

const api = new API(
  process.env.REACT_APP_HOST,
  process.env.REACT_APP_CLIENT_ID,
  process.env.REACT_APP_CLIENT_SECRET,
)
// ADD NEW MODELS HERE
api.registerModel(Asset)
api.registerModel(Operator)

export default api
