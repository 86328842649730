import { all, fork } from 'redux-saga/effects'
import API from './api'
import loginSagas from './components/login/sagas'
import forgotPasswordSagas from './views/forgotPassword/sagas'
import checkinManagerSagas from './components/checkinManager/sagas'
import lostAssetButtonSagas from './components/lostAssetButton/sagas'
import assetHistorySagas from './components/assetHistory/sagas'

export default function* rootSaga() {
  try {
    const apiSagas = API.sagas()
    yield all([
      ...apiSagas,
      loginSagas,
      checkinManagerSagas,
      lostAssetButtonSagas,
      assetHistorySagas,
      forgotPasswordSagas,
    ].map(fork))
  } catch (error) {
    console.log('ROOT-LEVEL SAGA ERROR', error)
  }
}
