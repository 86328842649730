import { takeLatest, all, call, put } from 'redux-saga/effects'
import  { ASSET_HISTORY_REQUEST, ASSET_HISTORY_RESPONSE } from './actionTypes'
import { getToken } from '../../utils/userToken'
import API from '../../api'
const emptyArray = []

function* fetchAssetHistory({ payload: { assetID } }) {
  try {
    const token = getToken()
    const response = yield call([API, API.fetchAssetHistory], token, assetID)
    const events = response.events || emptyArray

    yield put({
      type: ASSET_HISTORY_RESPONSE,
      payload: { events },
    })
  } catch (e) {
    console.warn('fetch asset history failed')
    console.error(e)
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ASSET_HISTORY_REQUEST, fetchAssetHistory),
  ])
}
