import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'

function LoginError(props) {
  return (
    <div className="login-error-animation}">
      <p className="login-error">{props.error}</p>
    </div>
  )
}

LoginError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default LoginError
