import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import {
  Button,
  ModalStyles,
  ModalHeader,
  ModalSubHeader,
  ModalBody,
  ModalActions,
} from '../elements'
import { reportLost as reportLostFn, reportFound as reportFoundFn } from './actions'

class LostAssetButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openModal() {
    this.setState({
      showModal: true,
    })
  }

  closeModal() {
    this.setState({
      showModal: false,
    })
  }

  reportLostConfirmed() {
    const { asset, reportLost, refresh } = this.props
    reportLost(asset.id, refresh)
    this.closeModal()
  }

  reportFoundConfirmed() {
    const { asset, reportFound, refresh } = this.props
    reportFound(asset.id, refresh)
    this.closeModal()
  }

  renderModal(content, btnText, contentLabel) {
    return (
      <>
        <Button onClick={()=>this.openModal()}>{btnText}</Button>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={()=>this.closeModal()}
          style={ModalStyles}
          contentLabel={contentLabel}
          ariaHideApp={false}
        >
          {content}
        </Modal>
      </>
    )
  }

  renderLost() {
    const { asset } = this.props
    const content = (
      <>
        <ModalHeader>Report Found</ModalHeader>
        <ModalSubHeader>Serial: {asset.serialNumber}</ModalSubHeader>
        <ModalBody>Are you sure you want to report this asset as found?</ModalBody>
        <ModalActions><Button onClick={()=>this.reportFoundConfirmed()}>Confirm</Button></ModalActions>
      </>)
    return this.renderModal(content, 'Found', 'Asset Found Confirmation')
  }

  render() {
    const { asset } = this.props
    if (asset.lostAt != null) {
      return this.renderLost()
    }

    const content = (
      <>
        <ModalHeader>Report Lost</ModalHeader>
        <ModalSubHeader>Serial: {asset.serialNumber}</ModalSubHeader>
        <ModalBody>Are you sure you want to report this asset as lost?</ModalBody>
        <ModalActions>
          <Button onClick={()=>this.closeModal()}>Cancel</Button>
          <Button onClick={()=>this.reportLostConfirmed()}>Confirm</Button>
        </ModalActions>
      </>)
    return this.renderModal(content, 'Lost', 'Asset Lost Confirmation')
  }
}

LostAssetButton.propTypes = {
  asset: PropTypes.any.isRequired,
  reportLost: PropTypes.func.isRequired,
  reportFound: PropTypes.func.isRequired,
  refresh: PropTypes.func,
}

const mapDispatchToProps = {
  reportLost: reportLostFn,
  reportFound: reportFoundFn,
}

export default connect(null, mapDispatchToProps)(LostAssetButton)
