import React from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { Frame, Icon } from '../elements'
import { logout as logoutAction } from '../login/actions'
import { getToken } from '../../utils/userToken'
import styled from 'styled-components'

const Header = styled.div`
  color: white;
  background: ${p => p.theme.primary};
  z-index: 2;
`

const HeaderContainer = styled(Frame)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8pt 0pt;
  margin-bottom: 6pt;
`

const AppTitle = styled.div`
  flex: 1;
  font-family: ${p => p.theme.headerFont};
  font-size: 24pt;
`

const ProfileBtn = styled(Icon)`
  font-size: 24pt;
  cursor: pointer;
  margin-right: 6pt;
`

const radius = '6pt';
const NavContainer = styled(Frame)`
  nav {
    display: flex;
    flex-direction: row;
    margin-left: -${radius};
  }
`

const NavBtnUnselected = styled.div`
  &:first-child {
    margin-left: 7pt;
  }

  font-size: 14pt;
  font-weight: 700;
  padding: 0px 0px 6px 0px;
  a {
    color: white;
    text-decoration: none;
    padding: 3pt 8pt;
    margin-bottom: 4pt;
  }
`

const NavBtnSelected = styled(NavBtnUnselected)`
  &:first-child {
    margin-left: 0pt;
  }

  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 0px;
  a {
    color: ${p => p.theme.primary};
    padding: 4pt 8pt;
  }
`

const OuterCurve = styled.div`
  width: ${radius};
  background-color: ${p => p.theme.primary};
  border-radius: 0px 0px ${radius} 0px;
`
const OuterCurveRight = styled(OuterCurve)`border-radius: 0px 0px 0px ${radius};`
// TODO: check to see how background-color is working without the $
const InnerCurveBack = styled.div`
  background-color: {p => p.theme.primary};
  padding: 0px 0px 6px 0px;
  a {
    background-color: white;
    border-radius: ${radius} ${radius} 0px 0px;
  }
`

const Padding = styled.div`
  height: 1pt;
`

function InnerCurve(props) {
  return (
    <InnerCurveBack>
      {props.children}
    </InnerCurveBack>
  )
}

function NavBtn (props) {
  const link = (<NavLink to={props.to}>{props.children}</NavLink>)

  if (props.selected) {
    return (
      <NavBtnSelected>
        <OuterCurve />
        <InnerCurve>{link}</InnerCurve>
        <OuterCurveRight />
      </NavBtnSelected>
    )
  }

  return (<NavBtnUnselected>{link}</NavBtnUnselected>)
}

function AppHeader({ logout, token, location }) {
  const selected = path => {
    const cur = location.pathname
    return cur.includes(path) || (cur === '/' && path === '/assets')
  }

  return (
    <Header>
      <HeaderContainer>
        <AppTitle>Asset Manager</AppTitle>
        { token && (<a role="button" tabIndex={0} onClick={logout}><ProfileBtn>logout</ProfileBtn></a>) }
      </HeaderContainer>
      { token ? (
        <NavContainer>
          <nav>
            <NavBtn to="/assets" selected={selected('/assets')}>Assets</NavBtn>
            <NavBtn to="/operators" selected={selected('/operators')}>Operators</NavBtn>
          </nav>
        </NavContainer>
      ) : (
        <Padding />
      )}
    </Header>
  )
}

export default connect(
  () => ({
    token: getToken(),
  }),
  {
    logout: logoutAction,
  },
)(withRouter(AppHeader))
