import { Record, List, Map } from 'immutable'
import { parseISO } from 'date-fns'
import { useStoreID, useOrgSubdomain } from '../../utils/hooks'
import CustomAction, { defaultEndpointMethod } from '../customAction'

class Operator extends Record({
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  status: '',
  createdAt: null,
  updatedAt: null,
}) {
  static get name() { return 'Operator' }

  static fromJS(object) {
    const {
      id,
      firstName,
      lastName,
      email,
      roleName: role,
      status,
      createdAt,
      updatedAt,
    } = object
    return new Operator({
      id,
      firstName,
      lastName,
      email,
      role,
      status,
      createdAt: parseISO(createdAt),
      updatedAt: parseISO(updatedAt),
    })
  }

  static listFromJS(objects) {
    if (!objects) return List()
    return List(objects.map(Operator.fromJS))
  }

  isNewRecord() {
    return this.id === 0
  }

  isActive() {
    switch (this.status) {
      case 'inactive':
      case 'deleted':
        return false
      default:
        return true
    }
  }

  static associateEmail() {
    const randName = Math.random().toString(36).substring(2)
    const domain = useOrgSubdomain()
    return `${randName}@${domain}.mti-internal`
  }

  baseEndpoint() {
    return `${this.constructor.baseEndpoint}${this.id}`
  }

  toSerializedHash() {
    return {
      user: {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email || this.constructor.associateEmail(),
      },
      persona: {'Store Associate':'store_associate', 'Store Manager':'store_manager'}[this.role],
      resourceIds: [ useStoreID() ],
    }
  }

  static destroyMethod() { return 'POST' }

  static recoverMethod() { return 'POST' }

  destroyEndpoint() {
    return `${this.baseEndpoint()}/deactivate`
  }

  recoverEndpoint() {
    return `${this.baseEndpoint()}/activate`
  }
}

Operator.baseEndpoint = 'api/users/'
Operator.apiCollection = 'users'
Operator.customActions = Map({
  deactivate: new CustomAction({
    method: 'POST',
    endpoint: self => `${defaultEndpointMethod(self)}/deactivate`,
  }),
  recover: new CustomAction({
    method: 'POST',
    endpoint: self => `${defaultEndpointMethod(self)}/activate`,
  }),
  initiatePasswordReset: new CustomAction({
    method: 'PUT',
    endpoint: self => `${defaultEndpointMethod(self)}/reset_password`,
    expectUpdated: false,
  }),
})

export default Operator
