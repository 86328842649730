import { Record } from 'immutable'
import { clearToken } from '../../utils/userToken'
import { useStoreID, useOrgSubdomain } from '../../utils/hooks'
import * as ActionTypes from './actions'

export const AuthState = Record({
  isLoading: false,
  error: null,
  user: null,
  token: null,
})

export const initialState = new AuthState()

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGIN_REQUEST:
      return state.merge({
        isLoading: true,
        error: null,
      })
    case ActionTypes.USER_LOGIN_SUCCESS:
      useStoreID(action.payload.user.rolesCache[0].storeId)
      useOrgSubdomain(action.payload.organization.subdomain)
      return state.merge({
        isLoading: false,
        error: null,
        user: action.payload.user,
        token: action.payload.token,
      })
    case ActionTypes.USER_LOGIN_FAILURE:
      useStoreID(null)
      useOrgSubdomain(null)
      return state.merge({
        isLoading: false,
        error: action.payload,
        user: null,
        token: null,
      })
    case ActionTypes.USER_LOGOUT_REQUEST:
      useStoreID(null)
      useOrgSubdomain(null)
      clearToken()
      return state.merge({
        isLoading: true,
        error: null,
      })
    case ActionTypes.USER_LOGOUT_SUCCESS:
      useStoreID(null)
      useOrgSubdomain(null)
      clearToken()
      return state.merge({
        isLoading: false,
        error: null,
        user: null,
        token: null,
      })
    case ActionTypes.USER_LOGOUT_FAILURE:
      return state.merge({
        isLoading: false,
        error: action.payload,
        user: null,
        token: null,
      })
    case ActionTypes.STORE_TOKEN_SUCCESS:
      return state.merge({
        token: action.payload,
      })
    case ActionTypes.STORE_TOKEN_FAILURE:
      return state.merge({
        token: null,
        error: action.payload,
        user: null,
      })
    default:
      return state
  }
}
