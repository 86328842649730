const tokenKey = 'token'

export const saveToken = token => {
  try {
    localStorage.setItem(tokenKey, token)
    return true
  } catch {
    return false
  }
}

export const getToken = () => localStorage.getItem(tokenKey)

export const clearToken = () => {
  try {
    localStorage.removeItem(tokenKey)
    return true
  } catch {
    return false
  }
}
