import { takeLatest, all, call, put } from 'redux-saga/effects'
import { CHECKIN_REQUEST, CHECKOUT_REQUEST, FETCH_SCANNED_ASSET, cacheScannedAsset } from './actions'
import { getToken } from '../../utils/userToken'
import API from '../../api'

function* checkout({ payload }) {
  try {
    const token = getToken()
    yield call([API, API.checkout], token, payload.assetID, payload.operatorID)
    if (payload.callback) { payload.callback() }
  } catch (e) {
    console.warn('checkout failed')
    console.error(e)
  }
}

function* checkin({ payload }) {
  try {
    const token = getToken()
    yield call([API, API.checkin], token, payload.assetID)
    if (payload.callback) { payload.callback() }
  } catch (e) {
    console.warn('checkin failed')
    console.error(e)
  }
}

function* fetchScannedAsset({ payload: { serial } }) {
  try {
    const token = getToken()
    const result = yield call([API, API.fetchAssetBySerial], token, serial)
    if (result.status === 200) {
      const key = result.body.physicalKeys[0]
      yield put(cacheScannedAsset(key, !key))
    } else {
      yield put(cacheScannedAsset(null, true))
    }
  } catch (e) {
    console.warn(`fetch asset by serial number failed: ${serial}`)
    console.error(e)
    yield put(cacheScannedAsset(null, true))
  }
}

export default function* root() {
  yield all([
    yield takeLatest(CHECKOUT_REQUEST, checkout),
    yield takeLatest(CHECKIN_REQUEST, checkin),
    yield takeLatest(FETCH_SCANNED_ASSET, fetchScannedAsset),
  ])
}
