import { Record } from 'immutable'
import { CACHE_SCANNED_ASSET } from './actions'

const CheckinManagerState = Record({
  asset: null,
  assetNotFound: false,
})

const initialState = new CheckinManagerState()

export default (state = initialState, action) => {
  switch (action.type) {
    case CACHE_SCANNED_ASSET:
      return state.merge({
        asset: action.payload.asset,
        assetNotFound: action.payload.assetNotFound,
      })
    default: return state
  }
}
