import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Operator } from '../../models'
import API from '../../api'

function OperatorName(props) {
  const { operator } = props
  if (!operator) return <>Unassigned</>
  return <>{operator.firstName} {operator.lastName} {operator.isActive() ? '' : '(inactive)'}</>
}

OperatorName.propTypes = {
  operatorID: PropTypes.number,
  operator: PropTypes.instanceOf(Operator),
}

const mapStateToProps = (state, props) => ({
  operator: props.operatorID && API.selectorFor('Operator', props.operatorID)(state),
})

export default connect(mapStateToProps)(OperatorName)
