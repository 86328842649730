import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { AssetList, AssetEditor, AssetHistory } from '../../components'
import CheckinManager from '../../components/checkinManager'
import Toolbar from '../../components/toolbar'
import {
  PageContainer,
  PageContentContainer,
  Button,
  ReadyIndicator,
  ErrorText,
  H2,
} from '../../components/elements'
import API from '../../api'
import Condition from '../../api/condition'
import { Asset } from '../../models'

class Assets extends React.Component {
  constructor(props) {
    super(props)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleHistoryClick = this.handleHistoryClick.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.toggleLost = this.toggleLost.bind(this)
    this.state = this.getFocusAssetFromParams({showLost: false})
  }

  getFocusAssetFromParams(extension={}) {
    const {
      assets,
      match: { params: { assetID, verb } },
    } = this.props
    const returnHash = {
      editingAsset: null,
      historyAsset: null,
    }
    const focusAsset = assets.find(asset => asset.id === (1*assetID))
    if (focusAsset) {
      if (verb === 'history') {
        returnHash.historyAsset = focusAsset
      } else {
        returnHash.editingAsset = focusAsset
      }
    }
    return Object.assign(extension, returnHash)
  }

  fetch() {
    const { index } = this.props
    const { showLost } = this.state
    const query = showLost ? { kept: false, discarded: true } : {}
    return index('Asset', query).then(() => {
      // Avoid memory leak in the event of authentication time-out.
      if (!this._isMounted) return
      index('Operator', { with_inactive: 'true' })
      this.setState(this.getFocusAssetFromParams())
    })
  }

  componentDidMount() {
    // TODO: Move query state into REDUX so that the current query can be
    // reloaded from other components and sub-components.
    this._isMounted = true
    const { condition } = this.props
    if (condition.isReady()) {
      this.fetch()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleEditClick(asset) {
    const { history } = this.props
    this.setState(
      { editingAsset: asset},
      () => history.push(`/assets/${asset.id}/edit`),
    )
  }

  handleHistoryClick(asset) {
    const { history } = this.props
    this.setState(
      {historyAsset: asset},
      () => history.push(`/assets/${asset.id}/history`),
    )
  }

  handleBackClick() {
    const { history } = this.props
    this.setState({
      editingAsset: null,
      historyAsset: null,
    }, () => {
      history.push('/assets')
      this.fetch()
    })
  }

  toggleLost() {
    const { showLost } = this.state
    this.setState({ showLost: !showLost }, () => this.fetch())
  }

  listView() {
    const { assets, condition, error } = this.props
    const { showLost } = this.state
    return (
      <>
        <Toolbar>
          <Button small onClick={this.toggleLost}>
            Show { showLost ? 'Current' : 'Lost' } Assets
          </Button>
          <Button small onClick={() => this.handleEditClick(new Asset())}>
            Create New Asset
          </Button>
        </Toolbar>
        <CheckinManager refresh={()=>this.fetch()} />
        <PageContentContainer>
          <H2><ReadyIndicator status={condition.name}/></H2>
          { error && <ErrorText>{error}</ErrorText> }
          <AssetList
            assets={assets}
            condition={condition}
            onEditClick={this.handleEditClick}
            onHistoryClick={this.handleHistoryClick}
            refresh={()=>this.fetch()}
          />
        </PageContentContainer>
      </>
    )
  }

  editView(asset) {
    const { api, condition, error, reload, update, create } = this.props
    return (
      <>
        <Toolbar />
        <PageContentContainer>
          <H2><ReadyIndicator status={condition.name}/></H2>
          { error && <ErrorText>{error}</ErrorText> }
          <AssetEditor
            selector={api.selectorFor('Asset', asset.id)}
            onCancel={this.handleBackClick}
            onReload={() => reload(asset)}
            onUpdate={update}
            onCreate={create}
            condition={condition}
          />
        </PageContentContainer>
      </>
    )
  }

  historyView(asset) {
    return (
      <>
        <Toolbar><Button onClick={this.handleBackClick}>Back</Button></Toolbar>
        <PageContentContainer>
          <AssetHistory asset={asset} />
        </PageContentContainer>
      </>
    )
  }

  stateView() {
    const { editingAsset, historyAsset } = this.state
    if (editingAsset) return this.editView(editingAsset);
    if (historyAsset) return this.historyView(historyAsset);
    return this.listView()
  }

  render() {
    return (
      <PageContainer>
        {this.stateView()}
      </PageContainer>
    )
  }
}

Assets.propTypes = {
  assets: PropTypes.instanceOf(List).isRequired,
  condition: PropTypes.instanceOf(Condition).isRequired,
  index: PropTypes.func.isRequired,
  fetch: PropTypes.func,
  reload: PropTypes.func.isRequired,
  create: PropTypes.func,
  update: PropTypes.func.isRequired,
  destroy: PropTypes.func,
  error: PropTypes.string,
  assetID: PropTypes.number,
  verb: PropTypes.string,
}

Assets.defaultProps = {
  fetch: () => {},
  create: () => {},
  destroy: () => {},
}

const mapStateToProps = state => ({
  assets: API.selectorFor('Asset')(state),
  condition: API.conditionSelectorFor('Asset')(state),
  error: API.errorSelectorFor('Asset')(state),
  api: API,
})

export default connect(mapStateToProps, API.curriedDispatches)(Assets)
