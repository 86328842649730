import React from 'react'
import { useTable, useSortBy } from 'react-table'
import {
  Matrix,
  MatrixHeader,
  MatrixCell,
  MatrixRow,
  SortIndicator,
} from '../elements'

// useTable can only be used inside a function component, rather than a class component
export default function Table({ data, columnSelector, columnVariables, initialSortBy = [] }) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: React.useMemo(columnSelector, (columnVariables || [])),
      data,
      initialState: {
        sortBy: initialSortBy,
      },
    },
    useSortBy,
  )
  return (
    <Matrix {...getTableProps()}>
      {headerGroups.map(headerGroup => {
        const { key: groupKey, role: groupRole } = headerGroup.getHeaderGroupProps()
        return <MatrixHeader cols={headerGroup.headers.length} key={groupKey} role={groupRole}>
          {headerGroup.headers.map(column => {
            const columnProps = column.getHeaderProps(column.getSortByToggleProps())
            const { key: columnKey } = columnProps
            return <MatrixCell key={columnKey} {...columnProps}>{column.render('Header')}{column.canSort && <SortIndicator isSortedDesc={column.isSortedDesc}/>}</MatrixCell>
          })}
        </MatrixHeader>
      })}
      {rows.map(row => {
        prepareRow(row)
        const { key: rowKey, role: rowRole } = row.getRowProps()
        return <MatrixRow cols={row.cells.length} key={rowKey} role={rowRole}>
          {row.cells.map(cell => {
            const cellProps = cell.getCellProps()
            const { key: cellKey, role: cellRole } = cellProps
            return <MatrixCell label={cell.column.Header} key={cellKey} role={cellRole}>{cell.render('Cell')}</MatrixCell>
          })}
        </MatrixRow>
      })}
    </Matrix>
  )
}
