import React from 'react'

class Scanner extends React.Component {
  componentDidMount() {
    this._onKeyInput = this._onKeyInput || this.onKeyInput.bind(this)
    document.addEventListener('keydown', this._onKeyInput);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._onKeyInput);
    clearTimeout(this._timer)
  }

  onKeyInput(e) {
    const character = e.key
    if (this.isValidChar(character)) {
      this.appendInput(character)
    } else if (e.code === 'Enter') {
      this.submit(e)
    } else {
      this._input = ''
    }
  }

  isValidChar(str) {
    if (str.length > 1) { return false }
    const code = str.charCodeAt(0)
    return (code >= 48 && code <= 57) || // [0-9]
      (code >= 65 && code <= 70) || // [A-F]
      (code >= 97 && code <= 102) // [a-f]
  }

  appendInput(character) {
    clearTimeout(this._timer)
    this._input = (this._input || '') + character
    this._timer = setTimeout(() => {
      this._input = ''
    }, 250) // usually only takes 30ms between characters for a real reader
  }

  submit(event) {
    const input = this._input || ''
    if (input.length < 1 || !this.props.onRead) { return }
    event.preventDefault()
    event.stopPropagation()
    this.props.onRead(input)
  }

  render() {
    return <></>;
  }
}

export default Scanner
