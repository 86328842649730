import { combineReducers } from 'redux-immutable'
import API from './api'
import loginReducers from './components/login/reducers'
import assetHistoryReducers from './components/assetHistory/reducers'
import checkinManagerReducers from './components/checkinManager/reducers'

export default combineReducers({
  auth: loginReducers,
  api: API.reducer,
  assetHistory: assetHistoryReducers,
  checkinManager: checkinManagerReducers,
})
