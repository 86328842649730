import { Record } from 'immutable'

export const defaultEndpointMethod = self => `/api/${self.contextClass().apiCollection}${self.collection ? '' : `/${self.context.id}`}`

export default class CustomAction extends (Record({
  method: 'GET',
  endpoint: defaultEndpointMethod, // Gets passed `this`, should return a string.
  serializer: null, // context => context.toJS(),
  query: null, // context => context.toJS(),
  conditionName: 'reading',
  collection: false,
  context: null,
  expectUpdated: true,
})) {

  contextClass() {
    return this.collection ? this.context : this.context.constructor
  }

  withContext(record) {
    return this.set('context', this.collection ? record.constructor : record)
  }

  shouldProceed(condition) {
    return condition.isReady()
  }

  conditionName() {
    if (['PUT', 'POST', 'PATCH', 'DELETE'].includes(this.method)) return 'writing'
    if (this.method === 'GET') return this.collection ? 'indexing' : 'reading'
    return 'ready'
  }

  shouldRetry(condition) {
    if (condition.isReady()) return true
    if (this.conditionName() === 'writing') return true
    if (this.conditionName() === 'indexing') {
      if (condition.isIndexing()) return false
      return true
    }
    if (this.conditionName() === 'reading') {
      return !condition.IDs.includes(this.context.id)
    }
    return false
  }

  formatQuery(query) {
    let q = query || this.query
    if (!q) return ''
    if (typeof q === 'function')
      q = q(this.context)
    if (typeof q === 'object')
      q = Object.entries(q).map(e => e.map(i => encodeURIComponent(i)).join('=')).join('&')
    if (typeof q !== 'string')
      throw new Error(`Unknown type ${typeof q} for query argument: ${q.toString()}`)
    if (q.length === 0) return ''
    if (q[0] !== '?') q = `?${q}`
    return q
  }

  formatEndpoint(query) {
    return `${this.endpoint(this)}${this.formatQuery(query)}`
  }

  serialize() {
    return this.serializer && this.serializer(this.context)
  }
}
