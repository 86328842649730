import { Record, Set } from 'immutable'

export default class Condition extends Record({name: 'ready', IDs: Set()}) {
  reset() {
    return this.merge({name: 'ready', IDs: Set()})
  }

  isIndexing() {
    return this.name === 'indexing'
  }

  isReading(id) {
    return this.isIndexing() || (this.name === 'reading' && this.IDs.includes(id))
  }

  // Not used in sagas
  isWriting(id) {
    return this.name === 'writing' && this.IDs.includes(id)
  }

  isReady() {
    return this.name === 'ready'
  }

  processPayload({name, id}) {
    switch (this.name) {
      case 'ready': // Nothing going on. Process any update.
        switch(name) {
          case 'ready':
            return this // No change.
          case 'indexing':
            return this.set('name', 'indexing') // Simply update the status.
          case 'reading':
            // We can only read something. If ID is blank, it's a problem.
            if (!id) throw new Error('Can\'t set condition \'reading\' to unknown ID.')

            // Set the status, and start the ID list.
            return this.merge({
              name: 'reading',
              IDs: Set([id]),
            })
          case 'writing':
            // Start the list. We might not have an ID yet, if it's a create
            // operation, so put the special value 'new' in the list.
            // NB: We can only track one create operation at a time. But
            // that's okay, because we only allow one write operation per
            // model at a time anyway.
            return this.merge({
              name: 'writing',
              IDs: Set([id || 'new']),
            })
          default:
            throw new Error(`Unexpected condition ${name} received while ${this.name} with ID "${id}"`)
        }
      case 'indexing':
        // Everything is halted while indexing. There is only one
        // proper condition.
        if (name === 'ready') return this.reset()
        throw new Error(`Unexpected condition ${name} received while ${this.name} with ID "${id}"`)
      case 'reading':
        switch(name) {
          case 'ready':
            if (this.IDs.includes(id)) {
              const newIDs = this.IDs.delete(id)
              // We might be reading more than one at a time.
              // If not, set the state back to 'ready'
              if (newIDs.isEmpty()) return this.reset()
              // If we are, remove this ID from the list.
              return this.set('IDs', newIDs)
            }
            // We might receive an unexpected ID from side-loaded
            // objects of another model's queries. Doesn't affect
            // our condition. Let them pass.
            return this
          case 'reading':
            // We can fetch new objects concurrently. Add the ID to
            // the list if it's not there already.
            if (this.IDs.includes(id)) return this
            return this.set('IDs', this.IDs.add(id))
          default:
            throw new Error(`Unexpected condition ${name} received while ${this.name} with ID "${id}"`)
        }
      case 'writing':
        switch(name) {
          case 'ready':
            if (this.IDs.includes(id)) {
              // We finished updating a record.
              const newIDs = this.IDs.delete(id)
              if (newIDs.isEmpty()) return this.reset()
              return this.set('IDs', newIDs)
            }
            if (this.IDs.includes('new')) {
              // We finished creating a new record.
              const newIDs = this.IDs.delete('new')
              if (newIDs.isEmpty()) return this.reset()
              return this.set('IDs', newIDs)
            }
            // Now this is a mystery. There's no reason we should get an unexpected ID from a write
            // operation.
            throw new Error(`Unexpected condition ${name} received while ${this.name} with ID "${id}"`)
          case 'writing':
            // Add the ID, or the special value 'new' for create operations, to
            // the list of IDs.
            return this.set('IDs', this.IDs.add(id || 'new'))
          default:
            throw new Error(`Unexpected condition ${name} received while ${this.name} with ID "${id}"`)
        }
      default:
        throw new Error(`Unexpected condition ${name} received while ${this.name} with ID "${id}"`)
    }
  }
}
