import { ASSET_HISTORY_RESPONSE } from './actionTypes'

const initialState = []
export default (state = initialState, action) => {
  switch (action.type) {
    case ASSET_HISTORY_RESPONSE:
      return action.payload.events
    default:
      return state
  }
}
