import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import DateTime from '../../components/dateTime'
import Condition from '../../api/condition'
import Table from '../table'
import {
  MatrixActions,
  Button,
} from '../elements'
import OperatorName from '../operatorName'
import LostAssetButton from '../lostAssetButton'

const columns = (onEditClick, onHistoryClick, refreshCallback) => [
  {
    Header: 'Name',
    accessor: 'name',
  }, {
    Header: 'Serial Number',
    accessor: 'serialNumber',
  }, {
    Header: 'Assigned To',
    disableSortBy: true,
    accessor: 'operatorID',
    Cell: ({value}) => <OperatorName operatorID={value}/>,
  }, {
    Header: 'Last Assigned',
    accessor: 'lastAssignedAt',
    Cell: ({value}) => <DateTime value={value}/>,
  }, {
    Header: 'Last Assigned By',
    accessor: 'lastAssignedByID',
    disableSortBy: true,
    Cell: ({value}) => <OperatorName operatorID={value}/>,
  }, {
    Header: 'Actions',
    id: 'actions',
    disableSortBy: true,
    Cell: ({ row: { original } }) => (
      <MatrixActions>
        {(!original.isLost()) && <Button onClick={() => onEditClick(original)}>Edit</Button>}
        <Button onClick={() => onHistoryClick(original)}>History</Button>
        <LostAssetButton asset={original} refresh={refreshCallback}/>
      </MatrixActions>
    ),
  },
]

export default function AssetList(props) {
  const { assets, onEditClick, onHistoryClick, refresh } = props
  return (
    <Table
      data={assets}
      columnSelector={()=>columns(onEditClick, onHistoryClick, refresh)}
      columnVariables={[onEditClick, onHistoryClick, refresh]}
    />
  )
}

AssetList.propTypes = {
  assets: PropTypes.instanceOf(List).isRequired,
  condition: PropTypes.instanceOf(Condition).isRequired,
  onEditClick: PropTypes.func,
  onHistoryClick: PropTypes.func.isRequired,
  refresh: PropTypes.func,
}
