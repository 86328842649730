import { takeLatest, all, call } from 'redux-saga/effects'
import { REPORT_LOST_REQUEST, REPORT_FOUND_REQUEST } from './actionTypes'
import { getToken } from '../../utils/userToken'
import API from '../../api'

function* reportLost({ payload }) {
  try {
    const token = getToken()
    yield call([API, API.reportLost], token, payload.assetID)
    if (payload.callback) { payload.callback() }
  } catch (e) {
    console.warn('report lost failed')
    console.error(e)
  }
}

function* reportFound({ payload }) {
  try {
    const token = getToken()
    yield call([API, API.reportFound], token, payload.assetID)
    if (payload.callback) { payload.callback() }
  } catch (e) {
    console.warn('report found failed')
    console.error(e)
  }
}

export default function* root() {
  yield all([
    yield takeLatest(REPORT_LOST_REQUEST, reportLost),
    yield takeLatest(REPORT_FOUND_REQUEST, reportFound),
  ])
}
