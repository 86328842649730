import { Record, Map, List } from 'immutable'
import { takeEvery, call, put, select, delay } from 'redux-saga/effects'
import { pluralize } from 'inflected'
import Condition from './condition'
import { createSelector } from 'reselect'
import { getToken } from '../utils/userToken'

export const ModelState = Record({condition: new Condition(), error: null, records: List()})

// Try not to hit the API redundantly.
const RETRY_DELAY = 500

const apiSelector = state => state.get('api') || Map()

export default class ModelSpec extends Record({
  name: '', plural: '', prefix: '', klass: null,
}) {

  constructor(klass) {
    super({
      name: klass.name,
      plural: pluralize(klass.name),
      prefix: `${klass.name.toUpperCase()}_`,
      klass,
    })
  }

  storeName() {
    if (!this._storeName) {
      this._storeName = `${this.plural[0].toLocaleLowerCase()}${this.plural.slice(1)}`
    }
    return this._storeName
  }

  apiCollection() {
    if (this.klass.apiCollection) return this.klass.apiCollection
    return this.storeName()
  }

  actionType(action) {
    return `${this.prefix}${action}`
  }

  index(request, query) {
    return new Promise((resolve, reject) => {
      const queryString =
        query && Object.keys(query).length >= 0 ?
          `?${Object.entries(query).map(([k,v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')}` :
          ''
      request(
        this.klass.indexMethod ? this.klass.indexMethod() : 'GET',
        (this.klass.indexEndpoint ? this.klass.indexEndpoint() : this.klass.baseEndpoint) + queryString,
      ).then(
        data => {
          resolve(data)
        },
        reject,
      )
    })
  }

  fetch(request, id) {
    return new Promise((resolve, reject) => {
      request(
        this.klass.fetchMethod ? this.klass.fetchMethod() : 'GET',
        this.klass.fetchEndpoint ? this.klass.fetchEndpoint(id) : this.klass.baseEndpoint + id,
      ).then(
        data => resolve(data),
        reject,
      )
    })
  }

  reload(request, record) {
    return new Promise((resolve, reject) => {
      request(
        this.klass.reloadMethod ? this.klass.reloadMethod() : 'GET',
        record.reloadEndpoint ? record.reloadEndpoint() : record.baseEndpoint(),
      ).then(
        data => resolve(data),
        reject,
      )
    })
  }

  create(request, record) {
    return new Promise((resolve, reject) => {
      request(
        this.klass.createMethod ? this.klass.createMethod() : 'POST',
        this.klass.createEndpoint ? this.klass.createEndpoint() : this.klass.baseEndpoint,
        record.toSerializedHash(),
      ).then(
        data => resolve(data),
        reject,
      )
    })
  }

  update(request, record) {
    return new Promise((resolve, reject) => {
      request(
        record.updateMethod ? record.updateMethod() : 'PATCH',
        record.updateEndpoint ? record.updateEndpoint() : record.baseEndpoint(),
        record.toSerializedHash(),
      ).then(
        data => resolve(data),
        reject,
      )
    })
  }

  destroy(request, record) {
    return new Promise((resolve, reject) => {
      request(
        this.klass.destroyMethod ? this.klass.destroyMethod() : 'DELETE',
        record.destroyEndpoint ? record.destroyEndpoint() : record.baseEndpoint(),
      ).then(
        data => resolve(data),
        reject,
      )
    })
  }

  customAction(request, customAction) {
    return new Promise((resolve, reject) => {
      request(
        customAction.method,
        customAction.formatEndpoint(),
        customAction.serialize(),
      ).then(
        data => resolve(data),
        reject,
      )
    })
  }

  errorAction(message) {
    return { type: this.actionType('ERROR'), payload: message }
  }

  // Central place for dealing with unfriendly errors.
  processError(original) {
    let error
    switch(original.constructor.name) { // An example.
      case 'TypeError':
        if (original.message === 'Failed to fetch') {
          error = new Error('Cloud server could not be reached. (Failed to make fetch happen.)')
        }
        break
      default:
        return original
    }
    if (error) {
      error.original = original
    } else {
      error = original
    }
    return error
  }

  makeIndexSaga(api) {
    return function* indexSagaListener() {
      yield takeEvery(this.actionType('INDEX'), function *indexSaga({ payload: { query, hook } }) {
        try {
          const condition = yield select(this.conditionSelector())
          if (condition.isReady()) {
            yield put({ type: this.actionType('SET_CONDITION'), payload: {name: 'indexing', id: null}})
            const token = getToken()
            try {
              const payload = yield call([this, this.index], this._makeRequest(api, token), query)
              for (const [spec, list] of api.modelsByCollection(payload)) {
                yield put({type: spec.actionType('INDEX_SUCCESS'), payload: list })
              }
              if (!payload[this.apiCollection()]) {
                yield put({type: this.actionType('INDEX_SUCCESS'), payload: List() })
              }
              hook.resolve()
            } catch (original) {
              const error = this.processError(original)
              yield put({type: this.actionType('ERROR'), payload: error.message })
              hook.reject(error)
            }
          } else if (!condition.isIndexing()) { // Discard the request if redundant.
            yield delay(RETRY_DELAY)
            yield put({type: this.actionType('INDEX'), payload: { query, hook } })
          }
        } catch (original) {
          const error = this.processError(original)
          yield put(this.errorAction(error.message))
          hook.reject(error)
        }
      }.bind(this))
    }.bind(this)
  }

  makeFetchSaga(api) {
    return function* fetchSagaListener() {
      yield takeEvery(this.actionType('FETCH'), function *indexSaga({ payload: { id, hook } }) {
        try {
          const condition = yield select(this.conditionSelector())
          if (condition.isReady()) {
            yield put({ type: this.actionType('SET_CONDITION'), payload: {name: 'reading', id } })
            const token = getToken()
            try {
              const payload = yield call([this, this.fetch], this._makeRequest(api, token), id)
              for (const [spec, list] of api.modelsByCollection(payload)) {
                yield put({type: spec.actionType('FETCH_SUCCESS'), payload: list })
              }
              hook.resolve()
            } catch (error) {
              yield put({type: this.actionType('ERROR'), payload: error.message })
              hook.reject(error)
            }
          } else if (!condition.isReading(id)) { // Discard the request if redundant.
            yield delay(RETRY_DELAY)
            yield put({ type: this.actionType('FETCH'), payload: { id, hook } })
          }
        } catch (error) {
          yield put(this.errorAction(error.message))
          hook.reject(error)
        }
      }.bind(this))
    }.bind(this)
  }

  makeReloadSaga(api) {
    return function* reloadSagaListener() {
      yield takeEvery(this.actionType('RELOAD'), function *indexSaga({ payload: { record, hook } }) {
        try {
          const condition = yield select(this.conditionSelector())
          if (condition.isReady()) {
            yield put({ type: this.actionType('SET_CONDITION'), payload: {name: 'reading', id: record.id} })
            const token = getToken()
            try {
              const payload = yield call([this, this.reload], this._makeRequest(api, token), record)
              for (const [spec, list] of api.modelsByCollection(payload)) {
                yield put({type: spec.actionType('FETCH_SUCCESS'), payload: list })
              }
              hook.resolve()
            } catch (error) {
              yield put({type: this.actionType('ERROR'), payload: error.message })
              hook.reject(error)
            }
          } else if (!condition.isReading(record.id)) { // Disard the request if redundant
            yield delay(RETRY_DELAY)
            yield put({ type: this.actionType('RELOAD'), payload: { record, hook } })
          }
        } catch(error) {
          yield put(this.errorAction(error.message))
          hook.reject(error)
        }
      }.bind(this))
    }.bind(this)
  }

  makeCreateSaga(api) {
    return function* createSagaListener() {
      yield takeEvery(this.actionType('CREATE'), function *indexSaga({ payload: { record, hook } }) {
        try {
          const condition = yield select(this.conditionSelector())
          if (condition.isReady()) {
            yield put({ type: this.actionType('SET_CONDITION'), payload: {name: 'writing', id: null } })
            const token = getToken()
            try {
              const payload = yield call([this, this.create], this._makeRequest(api, token), record)
              for (const [spec, list] of api.modelsByCollection(payload)) {
                yield put({type: spec.actionType('CREATE_SUCCESS'), payload: list })
              }
              const recordID = payload[this.klass.apiCollection][0].id
              const newRecord = yield select(this.selector(recordID))
              hook.resolve(newRecord)
            } catch(error) {
              yield put({type: this.actionType('ERROR'), payload: error.message })
              hook.reject(error)
            }
          } else { // Never discard the request
            yield delay(RETRY_DELAY)
            yield put({ type: this.actionType('CREATE'), payload: { record, hook } })
          }
        } catch (error) {
          const serverError = yield(error.message)
          yield put(this.errorAction(serverError.message))
          hook.reject(error)
        }
      }.bind(this))
    }.bind(this)
  }

  makeUpdateSaga(api) {
    return function* updateSagaListener() {
      yield takeEvery(this.actionType('UPDATE'), function *indexSaga({ payload: { record, hook } }) {
        try {
          const condition = yield select(this.conditionSelector())
          if (condition.isReady()) {
            yield put({ type: this.actionType('SET_CONDITION'), payload: {name: 'writing', id: record.id} })
            const token = getToken()
            try {
              const payload = yield call([this, this.update], this._makeRequest(api, token), record)
              for (const [spec, list] of api.modelsByCollection(payload)) {
                yield put({type: spec.actionType('UPDATE_SUCCESS'), payload: list })
              }
              const recordID = payload[this.klass.apiCollection][0].id
              const newRecord = yield select(this.selector(recordID))
              hook.resolve(newRecord)
            } catch(error) {
              yield put({type: this.actionType('ERROR'), payload: error.message })
              hook.reject(error)
            }
          } else { // We don't discard the write request.
            yield delay(RETRY_DELAY)
            yield put({ type: this.actionType('UPDATE'), payload: { record, hook } })
          }
        } catch (error) {
          yield put(this.errorAction(error.message))
          hook.reject(error)
        }
      }.bind(this))
    }.bind(this)
  }

  makeDestroySaga(api) {
    return function* destroySagaListener() {
      yield takeEvery(this.actionType('DESTROY'), function *indexSaga({ payload: { record, hook } }) {
        try {
          const condition = yield select(this.conditionSelector())
          if (condition.isReady()) {
            yield put({ type: this.actionType('SET_CONDITION'), payload: {name: 'writing', id: record.id} })
            const token = getToken()
            try {
              yield call([this, this.destroy], this._makeRequest(api, token), record)
              // NB: yield record, not payload on success. We need to know what to remove from the store.
              yield put({type: this.actionType('DESTROY_SUCCESS'), payload: record })
            } catch(error) {
              yield put({type: this.actionType('ERROR'), payload: error.message })
              hook.reject(error)
            }
            hook.resolve()
          } else { // We don't discard the request
            yield delay(RETRY_DELAY)
            yield put({ type: this.actionType('DESTROY'), payload: { record, hook } })
          }
        } catch (error) {
          const serverError = call(error.message)
          yield put(this.errorAction(serverError.message))
          hook.reject(error)
        }
      }.bind(this))
    }.bind(this)
  }

  // Function generator to create a listener saga.
  // TODO: This logic, with the "customAction" object could easily replace the other
  // CRUD sagas with a single, DRY saga. They're mostly redundant, and this one does
  // everything the others do.
  makeCustomActionSaga(api) {
    // The listener saga.
    return function* customActionSagaListener() {
      // List for a specific action, and define what to do inline.
      yield takeEvery(this.actionType('CUSTOM'), function *customActionSaga({ payload: { customAction, hook } }) {
        try {
          // Find out if we're already waiting on an API call.
          const condition = yield select(this.conditionSelector())

          // Some calls can proceed concurrently. Some should not.
          if (customAction.shouldProceed(condition)) {
            // Tell other sagas that this model is busy.
            yield put({ type: this.actionType('SET_CONDITION'), payload: {
              name: customAction.conditionName(),
              id: customAction.collection ? null : customAction.context.id,
            } })

            // Get the API token from local storage for authentication.
            const token = getToken()

            try {
              // TODO: This logic goes back and forth between API and ModelSpec a lot. Could be cleaned up a lot.
              // Find the memoized request function curried for this API token, and call it with the API endpoint
              // parameters described by customAction.
              const payload = yield call([this, this.customAction], this._makeRequest(api, token), customAction)

              // Most API endpoints will return models in a big array, which we can pull out and update our
              // store in a standardized way.
              if (customAction.expectUpdated) {
                for (const [spec, list] of api.modelsByCollection(payload)) {
                  yield put({type: spec.actionType('FETCH_SUCCESS'), payload: list })
                }
                hook.resolve() // Tell any process waiting on this API call that it went well.
              } else if (payload.error) {
                // The API did not return a standard model update.
                // Maybe it returned an error? Check that.
                const error = new Error(payload.error.message)
                error.original = payload.error
                hook.reject(error) // Tell any waiting processes that things went awry.
              } else {
                // Set this action concluded for this model.
                yield put({ type: this.actionType('SET_CONDITION'), payload: {
                  name: 'ready',
                  id: customAction.collection ? null : customAction.context.id,
                } })
                hook.resolve() // Tell any waiting processes that all went well.
              }
            } catch (error) {
              // Server error. Log and report it.
              yield put({type: this.actionType('ERROR'), payload: error.message })
              hook.reject(error)
            }
            // Here we are declining to run the saga because the model API is already busy.
            // Maybe we want to retry after a delay, or maybe we want to just give up.
            // (Running concurrent INDEX requests, for example, is just silly.)
          } else if (customAction.shouldRetry(condition)) { // Discard the request if redundant.
            yield delay(RETRY_DELAY)
            yield put({ type: this.actionType('CUSTOM'), payload: { customAction, hook } })
          }
        } catch (error) {
          // Any error not related to the actual API call is caught here.
          // That is, actual bugs in the code!
          yield put(this.errorAction(error.message))
          hook.reject(error)
        }
      }.bind(this))
    }.bind(this)
  }

  stateSelector() {
    if (!this._stateSelector) {
      this._stateSelector = createSelector(
        apiSelector,
        apiState => apiState.get(this.storeName()) || new ModelState(),
      )
    }
    return this._stateSelector
  }

  conditionSelector() {
    if (!this._conditionSelector) {
      this._conditionSelector = createSelector(
        this.stateSelector(),
        state => state.condition || new Condition(),
      )
    }
    return this._conditionSelector
  }

  errorSelector() {
    if (!this._errorSelector) {
      this._errorSelector = createSelector(
        this.stateSelector(),
        state => state.error || null,
      )
    }
    return this._errorSelector
  }

  selector(id = 0) {
    const Klass = this.klass
    if (!this._collectionSelector) {
      this._collectionSelector = createSelector(
        this.stateSelector(),
        subState => subState.records || List(),
      )
    }
    if (id===0) return () => new Klass()
    if (id === null) return this._collectionSelector
    if (!this._recordSelectors) this._recordSelectors = {}
    if (!this._recordSelectors[id]) {
      this._recordSelectors[id] = createSelector(
        this._collectionSelector,
        records => records.find(record => record.id === id),
      )
    }
    return this._recordSelectors[id]
  }

  clear(state) {
    return state.set(this.storeName(), new ModelState())
  }

  reduce(state, {type, payload}) {
    const match = type.match(new RegExp(`${this.prefix}(.*_SUCCESS|SET_CONDITION|ERROR)`))
    if (!match) return state
    const action = match[1]
    const subState = state.get(this.storeName()) || new ModelState()
    const newSubState = this._subReducer(subState, { type: action, payload })
    if (subState === newSubState) return state
    return state.set(this.storeName(), newSubState)
  }

  _subReducer(state = new ModelState(), {type, payload}) {
    switch(type) {
      case 'FETCH_SUCCESS':
      case 'UPDATE_SUCCESS':
      case 'CREATE_SUCCESS':
        // The API always returns an array, even when there's only one record.
        // So we replace the items in the array. This may also get called for
        // side-loaded models, in which case we may have an incomplete set of
        // records to upsert.
        return this.klass.listFromJS(payload).reduce((innerState, record) => (
          innerState.merge({
            condition: innerState.condition.processPayload({name: 'ready', id: record.id}),
            error: null,
            records: innerState.records
              .filterNot(item => item.id === record.id)
              .push(record)
              .sortBy(item => item.id),
          })
        ), state)

      case 'INDEX_SUCCESS':
        // This is a little simpler than the above case, because here we want
        // to clobber the list entirely.
        return new ModelState({
          condition: state.condition.processPayload({name: 'ready', id: null}),
          error: null,
          records: this.klass.listFromJS(payload).sortBy(record => record.id),
        })
      case 'DESTROY_SUCCESS':
        return state.merge({
          condition: state.condition.processPayload({name: 'ready', id: payload.id}),
          error: null,
          records: state.get('records')
            .filterNot(record => record.id === payload.id),
        })
      case 'SET_CONDITION':
        return state.set('condition', state.condition.processPayload(payload))
      case 'ERROR':
        return state.merge({
          error: payload,
          condition: state.condition.reset(), // Clobber any condition.
        })
      default:
        return state
    }
  }

  // Memoized curry function.
  _makeRequest(api, token) {
    if (this._token && token === this._token && this._request) return this._request
    this._token = token
    this._request = (method, endpoint, body) => api.request(token, method, endpoint, body)
    return this._request
  }
}
