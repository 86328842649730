import React from 'react'
import {
  Field,
  Form,
  ErrorMessage,
} from 'formik'
import Modal from 'react-modal'
import {
  FormRow,
  Button,
  BoldButton,
  ButtonGroup,
  ErrorText,
  H6,
  ModalStyles,
  ModalHeader,
  ModalSubHeader,
  ModalBody,
  ModalActions,
} from '../elements'

export default ({
  isSubmitting,
  onCancel,
  onDeactivate,
  onRecover,
  onInitiatePasswordReset,
  handleReset,
  submitForm,
  isValid,
  operator,
  values: {
    role,
    email,
  },
  errors,
  message,
}) => {
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] = React.useState(false)
  return <Form>
    {message && <H6>{message}</H6>}
    <FormRow label="Status">
      <strong>{operator.isActive() ? 'Active' : 'Inactive'}</strong>
    </FormRow>
    <FormRow name="role" label="Role">
      { operator.isNewRecord() ? <>
        <Field as="select" name="role">
          <option value="Store Associate">Store Associate</option>
          <option value="Store Manager">Store Manager</option>
        </Field>
        <ErrorMessage name="role" component={ErrorText}/>
      </> : <strong>{role}</strong>
      }
    </FormRow>
    <FormRow name="firstName" label="First Name">
      <Field name="firstName"/>
      <ErrorMessage name="firstName" component={ErrorText}/>
    </FormRow>
    <FormRow name="lastName" label="Last Name">
      <Field name="lastName"/>
      <ErrorMessage name="lastName" component={ErrorText}/>
    </FormRow>
    { (role === 'Store Manager') && (
      operator.isNewRecord() ? (
        <FormRow name="email" label="Email">
          <Field name="email" type="email"/>
          <ErrorMessage name="email" component={ErrorText}/>
        </FormRow>
      ) : (<>
        <FormRow name="email" label="Email"><strong>{email}</strong></FormRow>
        <ErrorMessage name="email" component={ErrorText}/>
      </>)
    )}
    { role === 'Store Associate' && errors.email && <ErrorMessage name="email" component={ErrorText}/> }
    <Modal
      isOpen={showDeactivateConfirmation}
      style={ModalStyles}
      contentLabel={operator.isActive() ? 'Deactivate Operator' : 'Activate Operator'}
      ariaHideApp={false}
    >
      <ModalHeader>
        {operator.isActive() ? 'Deactivate Operator' : 'Activate Operator'}
      </ModalHeader>
      <ModalSubHeader>
        {`${operator.firstName} ${operator.lastName}`.trim()}
      </ModalSubHeader>
      <ModalBody>
        Are you sure you want to {operator.isActive() ? 'deactivate' : 'reactivate'} this operator?
      </ModalBody>
      <ModalActions>
        <BoldButton cancel onClick={() => setShowDeactivateConfirmation(false)}>Cancel</BoldButton>
        <BoldButton onClick={() => operator.isActive() ? onDeactivate() : onRecover()}>Confirm</BoldButton>
      </ModalActions>
    </Modal>
    <ButtonGroup>
      <Button onClick={onCancel}>Back</Button>
      <Button cancel onClick={() => setShowDeactivateConfirmation(true)}>{operator.isActive() ? 'Deactivate' : 'Recover'}</Button>
      <Button secondary onClick={handleReset} disabled={isSubmitting}>Reset</Button>
      <Button type="submit" submit onClick={submitForm} disabled={isSubmitting || !isValid}>Apply</Button>
      { role === 'Store Manager' && operator.isActive() && <Button onClick={onInitiatePasswordReset}>Reset Password</Button>}
    </ButtonGroup>
  </Form>
}
