import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { logout } from '../login/actions'
import {
  BoldButton,
  ModalStyles,
  ModalHeader,
  ModalBody,
  ModalActions,
} from '../elements'

const IdleDuration = 600 // 10 minutes
const ModalDuration = 120 // show modal when there are only 2 minutes of time remaining
const initialState = {
  secondsRemaining: IdleDuration,
  showModal: false,
}

class IdleTimer extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    this.countdownTicker = setInterval(() => this.tick(), 1000)

    document.addEventListener('keypress', this.softResetCountdown)
    document.addEventListener('mouseup', this.softResetCountdown)
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.softResetCountdown)
    document.removeEventListener('mouseup', this.softResetCountdown)

    clearInterval(this.countdownTicker)
  }

  softResetCountdown = () => {
    // only reset on user activity if we haven't already opened the modal
    if (!this.state.showModal) {
      this.resetCountdown()
    }
  }

  resetCountdown() {
    this.setState(initialState)
  }

  tick() {
    const secondsRemaining = this.state.secondsRemaining - 1
    if (secondsRemaining <= 0) {
      // The logout action will clear the user data and redirect you regardless
      // of the API response, so this should only end up getting called once.
      // In the odd case that logout fails, then we'll just keep trying.
      this.handleExpiration()
    } else {
      this.setState({
        secondsRemaining,
        showModal: secondsRemaining <= ModalDuration,
      })
    }
  }

  handleExpiration() {
    this.props.logout()
  }

  render() {
    return (
      <Modal
        isOpen={this.state.showModal}
        style={ModalStyles}
        contentLabel="Session Timeout"
        ariaHideApp={false}
      >
        <ModalHeader>
          {'Session Timeout'}
        </ModalHeader>
        <ModalBody>
          Your session is about to expire. You will be logged out in {this.state.secondsRemaining} seconds.
        </ModalBody>
        <ModalActions>
          <BoldButton cancel onClick={() => this.resetCountdown()}>Stay Logged In</BoldButton>
          <BoldButton onClick={() => this.handleExpiration()}>Log Out Now</BoldButton>
        </ModalActions>
      </Modal>
    )
  }
}

IdleTimer.propTypes = {
  logout: PropTypes.func,
}

const mapDispatchToProps = {
  logout,
}

export default connect(null, mapDispatchToProps)(IdleTimer)
