/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { useTable, useSortBy } from 'react-table'
import {
  Matrix,
  MatrixHeader,
  MatrixCell,
  MatrixRow,
  SortIndicator,
} from '../elements'

export default function Table({ columns, data }) {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy,
  )

  return (
    <Matrix {...getTableProps()}>
      {headerGroups.map(headerGroup => {
        const { key: groupKey, role: groupRole } =
          headerGroup.getHeaderGroupProps()
        return (
          <MatrixHeader
            cols={headerGroup.headers.length}
            key={groupKey}
            role={groupRole}
          >
            {headerGroup.headers.map(column => {
              const columnProps = column.getHeaderProps(
                column.getSortByToggleProps(),
              )
              const { key: columnKey } = columnProps
              return (
                <MatrixCell key={columnKey} {...columnProps}>
                  {column.render('Header')}
                  {column.canSort && (
                    <SortIndicator isSortedDesc={column.isSortedDesc} />
                  )}
                </MatrixCell>
              )
            })}
          </MatrixHeader>
        )
      })}
      {rows.map(row => {
        prepareRow(row)
        const { key: rowKey, role: rowRole } = row.getRowProps()
        return (
          <MatrixRow cols={row.cells.length} key={rowKey} role={rowRole}>
            {row.cells.map(cell => {
              const { key: cellKey, role: cellRole } = cell.getCellProps()
              return (
                <MatrixCell key={cellKey} role={cellRole}>
                  {cell.render('Cell')}
                </MatrixCell>
              )
            })}
          </MatrixRow>
        )
      })}
    </Matrix>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}
