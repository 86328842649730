import { takeLatest, all, call, put, select } from 'redux-saga/effects'
import API from '../../api'
import { saveToken, clearToken } from '../../utils/userToken'
import { getToken } from './selectors'
import {
  USER_LOGIN_REQUEST,
  USER_LOGOUT_REQUEST,
  STORE_TOKEN_REQUEST,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  storeTokenSuccess,
  storeTokenFailure,
} from './actions'

export const generalError = 'There was an error processing your request'

// these are the sagas that get added to the root saga in src/sagas.js

export function* loginRequest({ payload }) {
  try {
    const data = yield call([API, API.authenticate], payload)
    const { token } = data.authentications[0]
    const user = data.users[0]
    const organization = data[user.organizationCollection].find(org => org.id === user.organizationId)
    if (saveToken(token)) {
      yield put(loginSuccess({ user, token, organization }))
    }
  } catch (e) {
    clearToken()
    yield put(loginFailure(e.message))
  }
}

export function* logoutRequest() {
  try {
    if (clearToken()) {
      const token = yield select(getToken)
      if (token) {
        yield call([API, API.deauthenticate], token)
      }
      yield put(logoutSuccess())
    }
  } catch (e) {
    yield put(logoutFailure(API.lastError ? API.lastError.message : generalError))
  }
}

export function* storeTokenRequest({ payload }) {
  try {
    if (saveToken(payload)) {
      yield put(storeTokenSuccess(payload))
    }
  } catch (e) {
    yield put(storeTokenFailure(API.lastError ? API.lastError.message : generalError))
  }
}

export default function* root() {
  yield all([
    yield takeLatest(USER_LOGIN_REQUEST, loginRequest),
    yield takeLatest(USER_LOGOUT_REQUEST, logoutRequest),
    yield takeLatest(STORE_TOKEN_REQUEST, storeTokenRequest),
  ])
}
