import { Record, List } from 'immutable'
import { parseISO, formatISO } from 'date-fns'

class Asset extends Record({
  id: 0,
  name: '',
  serialNumber: '',
  createdAt: null,
  updatedAt: null,
  lostAt: null,
  operatorID: null,
  lastAssignedAt: null,
  lastAssignedByID: null,
}) {
  static get name() { return 'Asset' }

  static fromJS(object) {
    const {
      id,
      name,
      serialNumber,
      createdAt,
      updatedAt,
      discardedAt: lostAt,
      userId: operatorID,
      lastAssignedAt,
      lastAssignedById: lastAssignedByID,
    } = object
    return new Asset({
      id,
      name,
      serialNumber,
      createdAt: createdAt && parseISO(createdAt),
      updatedAt: updatedAt && parseISO(updatedAt),
      lostAt: lostAt && parseISO(lostAt),
      operatorID,
      lastAssignedAt: lastAssignedAt && parseISO(lastAssignedAt),
      lastAssignedByID,
    })
  }

  static listFromJS(objects) {
    if (!objects) return List()

    const theList = List(objects.map(Asset.fromJS))
      .sortBy(asset => asset.id)
    return theList
  }

  toSerializedHash() {
    return {
      physicalKey: {
        discardedAt: this.lostAt && formatISO(this.lostAt),
        name: this.name,
        userId: this.operatorID,
        serialNumber: this.serialNumber,
      },
    }
  }

  static fetchAll(dispatch, discarded) {
    dispatch({
      type: 'ASSET_INDEX',
      payload: { discarded },
    })
  }

  isLost() {
    return this.lostAt !== null
  }

  markLost() {
    return this.set('lostAt', new Date())
  }

  markUnlost() {
    return this.set('lostAt', null)
  }

  baseEndpoint() {
    return `${Asset.baseEndpoint}${this.id}`
  }

  isNewRecord() {
    return this.id === 0
  }
}
Asset.baseEndpoint = 'api/physical_keys/'
Asset.apiCollection = 'physicalKeys'

export default Asset
