import { Record } from 'immutable'

export default class Hook extends(Record({
  resolve: () => {},
  reject: () => {},
  promise: null,
})) {
  static withPromise() {
    let hook = new Hook()
    const promise = new Promise((resolve, reject) => {
      hook = hook.merge({resolve, reject})
    })
    return hook.set('promise', promise)
  }
}

