import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import Color from 'color'

const breakpoints = {
  phoneMax: '599px',
  tabletPortraitMin: '600px',
  tabletPortraitMax: '899px',
  tabletLandscapeMin: '900px',
  tabletLandscapeMax: '1199px',
  desktopMin: '1200px',
  desktopMax: '1799px',
  hugeDesktopMin: '1800px',
}

/* eslint-disable no-unused-vars */
const mtiRed = '#c10824'
const mtiNavy = '#1e2e42'
const mtiFluid = '#6387bc'
const mtiArctic = '#83a0ae'
const mtiSlate = '#4f6367'
const mtiHue1 = '#242c2e'
const mtiHue2 = '#565e61'
/* eslint-enable no-unused-vars */

export const primaryColor = Color(mtiNavy)

export const theme = {
  primary: primaryColor.hex(),
  dark: primaryColor.lightness(25).hex(),
  medium: primaryColor.lightness(50).desaturate(0.5).hex(),
  light: primaryColor.rotate(180).desaturate(0.5).lightness(95).hex(),
  accent: mtiRed,
  text: primaryColor.lightness(10).hex(),
  textAlt1: primaryColor.rotate(150).lightness(10).hex(),
  textAlt2: primaryColor.rotate(210).lightness(10).hex(),
  bodyFont: "'Open Sans', sans-serif",
  headerFont: 'Montserrat, serif',
  bodyWeight: 100,
  bodyWeightBold: 500,
  bodyWeightBlack: 900,
  headerWeight: 400,
  fontSize: '10pt',
  borderRadius: '0.25em',
  button: mtiNavy,
  buttonWeight: 600,
  divider: '#cccccc',
}

const headerStyles = css`
  margin-top: 0;
  margin-bottom: 2ex;
  font-family: ${p => p.theme.headerFont};
  font-weight: ${props => props.theme.headerWeight};
`

export const H1 = styled.h1`
  ${headerStyles}
  font-size: 18pt;
  color: ${props => props.theme.text};
`

export const H2 = styled.h2`
  ${headerStyles}
  font-size: 16pt;
  color: ${props => props.theme.textAlt1};
`

export const H3 = styled.h3`
  ${headerStyles}
  font-size: 14pt;
  color: ${props => props.theme.textAlt2};
`

export const H4 = styled.h4`
  ${headerStyles}
  font-size: 12pt;
  color: ${props => props.theme.textAlt1};
`

export const H5 = styled.h5`
  ${headerStyles}
  font-size: 10pt;
  color: ${props => props.theme.textAlt2};
`

export const H6 = styled.h6`
  ${headerStyles}
  font-size: 10pt;
  color: ${props => props.theme.textAlt1};
`

export const Frame = styled.div`
  width: calc(100% - 16pt);
  max-width: 900px;
  margin: 0 auto;
`

export const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`

export const PageContentContainer = styled(Frame)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`

export const Header = styled.header`
  color: white;
  background: ${props => props.theme.dark};
  margin-bottom: 2ex;
  width: 100%;
  padding: 0;

  & > div {
    flex-direction: row;
  }
  & nav {
    flex-grow: 10;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;

      li {
        display: flex;
        list-decoration: none;

        a {
          display: inline-block;
          text-decoration: none;
          color: ${props => props.theme.text};
          padding: 1ex 1em;
          background-color: white;
          border-radius: ${props => props.theme.borderRadius};
          transition: background-color 0.5s;
          font-weight: ${props => props.theme.bodyWeightBold};

          &:active,
          &:visited {
            color: ${props => props.theme.dark};
          }

          &:hover {
            background-color: ${props => props.theme.light};
          }

          &.active {
            font-weight: ${props => props.theme.bodyWeightBlack};
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 0;

    & h1 {
      font-size: 110%;
      font-weight: ${props => props.theme.headerWeight};
    }

    & nav ul li a {
      font-size: 100%;
      background: inherit;
      display: inline-block;
      padding: 0;
      margin: 0;
      color: ${props => Color(props.theme.textAlt1).lightness(90)};
      &:hover,
      &:visited {
        color: ${props => Color(props.theme.textAlt1).lightness(90)};
        background: inherit;
      }
      &:active {
        color: ${props => Color(props.theme.textAlt2).lightness(90)};
        background: inherit;
      }
    }
  }
`

export const Container = styled(PageContainer)`
  padding: 1ex 2ex;
  display: flex;
  flex-direction: column;
`

export const ViewContainer = styled.div.attrs(props => ({
  'data-status': props.status || 'ready',
}))`
`

export const Card = styled.div`
  border: 1px solid ${props => props.theme.medium};
  border-radius: 0.25em;
  background-color: ${props => props.theme.light};
  padding: 1ex 1em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 2ex;
  box-shadow: 0 0 16px 4px #EEEEEE;

  ${ViewContainer}:not([data-status="ready"]) & {
    background-color: ${props => Color(props.theme.light).desaturate(1)};
  }
`

export const Button = styled.button.attrs(p => ({
  type: p.type || 'button',
}))`
  background-color: inherit;
  font-weight: ${props => props.theme.buttonWeight };
  color: ${p => p.theme.button};
  border: none;
  border-radius: 2px;
  outline: none;
  padding: 4pt 8pt;
  margin: 0pt;
  cursor: pointer;

  &[disabled] {
    color: ${props => Color(props.theme.button).desaturate(1.0).lightness(60)};
  }
`

export const BoldButton = styled(Button)`
  background-color: ${p => p.theme.primary};
  color: white;
  padding: 6pt 12pt;
  margin: 2pt;
`

export const Dropdown = styled.select`
  padding: ${p => p.small ? '0.25ex 0.5em' : '1ex 1em'};
  background-color: ${p => p.theme.medium};
  color: white;
  border-radius: ${p => p.theme.borderRadius};
  border: none;
`

export const ButtonGroup = styled.div`
  & ${Button} {
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${props => props.theme.light};

    &:first-child {
      border-top-left-radius: ${props => props.theme.borderRadius};
      border-bottom-left-radius: ${props => props.theme.borderRadius};
      border-left: none;
    }

    &:last-child {
      border-top-right-radius: ${props => props.theme.borderRadius};
      border-bottom-right-radius: ${props => props.theme.borderRadius};
    }
  }
`

export const TextButton = styled.a.attrs(() => ({
  href: '#',
  role: 'button',
}))`
  text-decoration: none;
  color: inherit;

  &:visited,
  &:hover,
  &:active {
    color: inherit;
  }

  &:hover {
    font-weight: bold;
  }
`

export const InputField = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1rem;
  height: 1.75em;
  line-height: 1.5;
  padding: 5px 10px;
  color: #363636;
  -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  margin-bottom: 8pt;
`

export const Matrix = styled.div`
  display: flex;
  flex-direction: column;
`

export const MatrixCell = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.tabletPortraitMax}) {
    display: block;
    width: 100%;
    margin-bottom: 0.25em;

    & div:first-of-type {
      display: inline-block;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '${props => props.label ? `${props.label}: ` : ''}';
      font-weight: ${props => props.theme.bodyWeightBold};
      width: ${props => props.label ? '30%' : ''};
      display: inline-block;
      vertical-align: top;
    }
  }
  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    &:before {
      width: ${props => props.label ? '50%' : ''};
    }
  }
`

export const MatrixActions = styled.div`
  & ${Button} {
    padding: 0.5em 1.0ex;
    font-size: smaller;
  }

  & ${Button}:first-child {
    margin-left: 0;
  }

  @media only screen and (max-width: ${breakpoints.tabletPortraitMax}) {
    display: inline-block;
    padding: 0;
  }
`

const matrixRowInternal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & ${MatrixCell} {
    width: ${props => props.cols ? `calc(100% / ${props.cols})` : 'auto'};
  }
`

export const MatrixRow = styled(matrixRowInternal)`
  border-bottom: 1px solid ${props => props.theme.divider};
  padding: 1ex 0;

  &:last-child {
    border-bottom: none;
  }

  @media only screen and (max-width: ${breakpoints.tabletPortraitMax}) {
    flex-direction: column;
    & ${MatrixCell} {
      display: block;
      width: 100%;
    }
  }
`

export const MatrixHeader = styled(matrixRowInternal)`
  font-weight: ${props => props.theme.bodyWeightBold};
  color: ${props => props.theme.textAlt1};
  @media only screen and (max-width: ${breakpoints.tabletPortraitMax}) {
    display: none;
  }
`

export const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2ex 1em;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Icon = styled.span.attrs(props => ({
  className: `material-icons${props.spin ? ' spin': ''}`,
}))`
  font-size: inherit;
  &.spin {
    animation: ${rotate} 2s linear infinite;
  }
`
export const HoverIcon = styled.span.attrs(() => ({ className: 'material-icons' }))`
  font-size: inherit;
  visibility: hidden;
  *:hover > & {
    visibility: visible;
  }
`

export const SortIndicator = props => (<>
  &nbsp;
  {(typeof props.isSortedDesc === 'undefined') ? <HoverIcon className="md-18">sort_by_alpha</HoverIcon>
    : <Icon>{props.isSortedDesc ? 'arrow_downward' : 'arrow_upward' }</Icon>}
</>)

export const ReadyIndicator = props => (<>
  &nbsp;<small><Icon spin>{props.status === 'ready' ? '' : 'autorenew'}</Icon></small>
</>)

export const Column = styled.div`
  flex-direction: column;
  flex-wrap: no-wrap;
`

export const Row = styled.div`
  flex-direction: row;
  flex-wrap: no-wrap;
  & * {
    display: inline-block;
  }
`

export const ModalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible',
  },
}

export const ModalHeader = styled.h2`
  margin: 0px;
  font-family: ${p => p.theme.headerFont};
  font-size: 18pt;
`

export const ModalSubHeader = styled.h3`
  margin: 0px;
  font-weight: 300;
`

export const ModalBody = styled.div`
  margin: 8pt;
`

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: inherit;
`

export const ErrorMessage = styled.div`
  color: ${props => props.theme.textAlt2};
`

export const ErrorText = styled.div`
  color: red;
`

export const FormFieldSet = styled(Column)`
`

export const FormFieldRow = styled(Row)`
  padding-bottom: 1ex;
  align-items: stretch;
`
export const FormFieldLabel = styled.label`
    display: inline-block;
    font-weight: ${props => props.theme.bodyWeightBold};
    flex-grow: 1;
    margin-right: 1em;
    width: 16.6%;
`

export const FormFieldWrapper = styled.div`
    display: inline-block;
    flex-grow: 2;
    & ${ErrorText} {
      display: inline-block;
      flex-grow: 2;
      margin-left: 2em;
    }
`

export const FormRow = props => (
  <FormFieldRow>
    <FormFieldLabel htmlFor={props.name}>{props.label}</FormFieldLabel>
    <FormFieldWrapper>{props.children}</FormFieldWrapper>
  </FormFieldRow>
)

const fade = keyframes`
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
`

const FlashInner = styled(H4)`
  animation: ${fade} 20s ease-in both;
`

export const Flash = ({message, onClear}) => (message.length > 0 ? <FlashInner>{message} <TextButton onClick={onClear}><small><Icon>close</Icon></small></TextButton></FlashInner> : <H4>&nbsp;</H4>)
