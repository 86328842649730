import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import { connect } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'
import './fonts.css'
import './app.css'
import { ForgotPassword, Assets, Operators } from './views'
import { Login } from './components'
import { getToken } from './utils/userToken'
import {
  theme,
  Frame,
} from './components/elements'
import AppHeader from './components/appHeader'
import IdleTimer from './components/idleTimer'

const AppContainer = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: ${p => p.theme.bodyFont};
  font-weight: 300;
  font-size: 10pt;
  display: flex;
  flex-direction: column;
`

export const Body = styled.div`
  flex: 1;
  position: relative;
`

export const Footer = styled.footer`
  width: 100%;
  background-color: #111111;
  color: ${props => props.theme.light};
  font-size: x-small;
  padding: 3pt;
`

function App ({ token }) {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <Router>
          <Route
            path={['/']}
            render={() => <AppHeader />}
          />
          <Body>
            { token ? (
              <>
                <IdleTimer />
                <Switch>
                  <Route
                    path={['/operators/:operatorID', '/operators']}
                    render={props => <Operators {...props}/>}
                  />
                  <Route
                    path={['/assets/:assetID/:verb', '/assets/:assetID', '/assets', '/']}
                    render={props => <Assets {...props}/>}
                  />
                </Switch>
              </>
            ) : (
              <Switch>
                <Route
                  path={['/forgot_password']}
                  render={() => <ForgotPassword />}
                />
                <Route
                  path={['/']}
                  render={() => <Login />}
                />
              </Switch>
            )}
          </Body>
        </Router>
        <Footer>
          <Frame>
            &copy; {(new Date()).getFullYear()} Mobile Technologies Inc. All rights reserved.
          </Frame>
        </Footer>
      </AppContainer>
    </ThemeProvider>
  )
}

export default connect(
  () => ({
    token: getToken(),
  }),
)(App)
