import React from 'react'
import Scanner from '../scanner'
import {
  Form as FkForm,
  Field,
  ErrorMessage,
} from 'formik'
import {
  Button,
  ButtonGroup,
  FormFieldSet,
  FormRow,
  ErrorText,
  H6,
} from '../elements'
import OperatorName from '../operatorName'
import DateTime from '../dateTime'

export default function Form(props) {
  const {
    isSubmitting,
    handleReset,
    onCancel,
    readOnlyValues,
    isValid,
    message,
    setFieldValue,
  } = props
  return (
    <>
      <Scanner
        onRead={serial => {
          setFieldValue('serialNumber', serial)
          setFieldValue('confirmSerialNumber', serial)
        }}
      />
      <FkForm>
        {message && <H6>{message}</H6>}
        <FormFieldSet>
          <FormRow name="name" label="Name">
            <Field name="name" type="text"/>
            <ErrorMessage name="name" component={ErrorText}/>
          </FormRow>
          <FormRow label="Serial Number" name="serialNumber">{readOnlyValues.id ? readOnlyValues.serialNumber : <>
            <Field  type="text" name="serialNumber"/>
            <ErrorMessage name="serialNumber" component={ErrorText}/>
          </> }</FormRow>
          { !readOnlyValues.id && <FormRow label="Confirm Serial Number" name="confirmSerialNumber">
            <Field  type="text" name="confirmSerialNumber"/>
            <ErrorMessage name="confirmSerialNumber" component={ErrorText}/>
          </FormRow> }
          <FormRow label="Assigned To"><OperatorName operatorID={readOnlyValues.operatorID}/></FormRow>
          <FormRow label="Last Assigned"><DateTime value={readOnlyValues.lastAssignedAt}/></FormRow>
          <FormRow label="Last Assigned By"><OperatorName operatorID={readOnlyValues.lastAssignedByID}/></FormRow>
        </FormFieldSet>
        <ButtonGroup>
          <Button onClick={onCancel} cancel disabled={isSubmitting}>Back</Button>
          <Button onClick={handleReset} disabled={isSubmitting}>Reset</Button>
          { readOnlyValues.id ? <>
            <Button submit type="submit" name="apply" disabled={isSubmitting || !isValid}>Apply</Button>
          </> : <>
            <Button
              type="submit"
              submit
              primary
              name="submit"
              disabled={isSubmitting || !isValid}
            >
              Create
            </Button>
          </> }
        </ButtonGroup>
      </FkForm>
    </>
  )
}
