import React from 'react'
import { format } from 'date-fns'
import {
  Column,
  Row,
} from '../../components/elements'

export default function DateTime(props) {
  const { value } = props
  if (!value) { return (<></>) }
  const date = typeof value === 'string' ? Date.parse(value) : value
  return (<Column>
    <Row>{format(date, 'PPP')}</Row>
    <Row>{format(date, 'pp')}</Row>
  </Column>)
}
